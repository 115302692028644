.page-header {

  button {
    outline: none;
  }
  margin-bottom: 0 !important;

  .logo {
    margin: auto;
    img {
      margin-top: 0 !important;
    }
  }

  .responsive-menu {
    display: none;
    text-align: center;
    margin: auto;

    .responsive-button {
      margin-top: 8px;
      padding: 0;

      &:hover {
        opacity: 1;
      }


      .hamburger-box {
        width: 25px;

        .hamburger-inner {
          background: white;
          width: 25px;
          height: 3px;

          &:before {
            background: white;
            width: 25px;
            height: 3px;
          }
          &:after {
            background: white;
            width: 25px;
            height: 3px;
          }
        }
      }
    }
  }

  .responsive-search {
    display: none;
    margin: auto;
    text-align: center;

    .responsive-search-button {
      background: none;
      border: none;
      color: white;
      font-size: 26px;
      margin-top: 3px;

      .zmdi-close {
        display: none;
      }

      &.is-active {
        .zmdi-close {
          display: inline-block;
        }

        .zmdi-search {
          display: none;
        }
      }
    }
  }
}

.mobile-search-active {
  display: block !important;
  position: absolute;
  z-index: 999;
  top: 70px;
  height: 70px;
  background: #606060;
  padding: 0;

  .block-search {
    height: 100%;
    margin-top: 0 !important;

    .block-content {
      height: 100%;

      .form.minisearch {
        height: 100%;

        .action.search {
          display: block;

          span {
            display: none;
          }

          &:before {
            font: normal normal normal 35px/1 'Material-Design-Iconic-Font';
            content: '\f1c3';
            position: relative;
            top: 8px;
          }

        }

        .field.search {
          height: 100%;

          .control {
            height: 100%;
            clear: none;

            input {
              left: 0;
              margin: 0;
              position: absolute;
              line-height: 69px;
            }

          }
        }
      }
    }
  }
}

.menu__mobile {
  display: none;
}

.mobile-active {
  max-height: 1000px !important;
  transition: max-height 0.5s ease-out;
  width: 100%;
  height: calc(100vh - 70px);
  background: white;
  z-index: 999;
  top: 70px;

  .menu--main {
    &:after {
      display: none;
    }
  }

  .active-menu {
    transition: width 0.5s;
  }

  .custom-submenu {
    top: 0;
    position: absolute;
    width: 0;
    left: auto;
    height: calc(100vh - 70px);
    transition: width 0.5s;
    white-space: nowrap;
    box-shadow: none;

    &.active-menu {
      box-shadow: -15px 0px 23px -6px rgba(181, 181, 181, 1);
      width: 90% !important;
    }

    .container-fluid {
      height: 100%;
      overflow: scroll;

    }

    .container-fluid > ul {
      column-count: 1 !important;
      li {
        border-right: none;
      }
    }

    .block.widget.block-products-list.grid {
      display: none;
    }
    .close-submenu {
      left: -23px;
      top: 30px;
      margin-left: 0;
      height: 43px;
      width: 43px;
      text-align: center;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      a {
        line-height: 2;
      }

    }

    .custom-menu-back {
      display: none;
    }
  }
}

@media only screen and (max-width: 1400px) {
  #search {
    font-size: 14px !important;
  }
  .fotorama__stage {
    margin-left: 0 !important;
  }
  .fotorama__nav-wrap--vertical.fotorama__nav-wrap {
    display: none !important;
  }

}

@media only screen and (max-width: 1330px) {
  .custom-submenu {
    padding: 0 !important;
  }
  .menu__item {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1200px) {
  .page-header {
    > .container {
      max-width: 100% !important;
    }
    .page-header__account {
      .header.links {
        width: 50%;
        margin: 0;
      }
      .minicart-wrapper {
        width: 50%;
        margin: 0;
      }
    }
  }

  .checkout-index-index {
    .container {
      max-width: 100%;
    }
  }

//  .product-info-tabs {
//    flex-basis: initial;
//  }
//  .data.item.content {
//    padding-left: 0 !important;
//  }
}

@media only screen and (max-width: 1140px) {

  .perk-band--tall {
      display: none;
  }

  .main-menu-container {
    max-height: 0;
    transition: max-height 0.5s;
    overflow: auto;

    .menu__item {
      list-style: none !important;
      display: block;
      text-align: left;
      list-style: none;
    }
  }

  .search--header {
    display: none;
    max-width: 100%;
  }
  .submenu {
    display: none;
  }
  .responsive-search {
    display: inline-block !important;
  }
  .responsive-menu {
    display: inline-block !important;
  }
}

@media only screen and (max-width: 990px) {
  .page-header__account {
    text-align: center !important;
    .responsive-account {
      display: none;
    }
    .responsive-basket {
      display: none;
    }
    .minicart-wrapper {
      margin-top: 0 !important;
    }
    .greet.welcome {
      span {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #newsletter-validate-detail {
    display: block;

    #newsletter {
      margin-top: 20px;
    }
    .actions {
      margin-top: 20px;
    }
  }

  .opc-wrapper {
    padding: 30px;
    width: 100% !important;
  }

  .electric {
    text-align: center !important;
    img {
      margin: auto !important;
    }
  }

  .product.info.detailed .data.item.content {
//    padding: 0 !important;
    img {
      object-fit: contain;
    }
  }

  .breadcrumbs {
    padding: 10px 5px !important;
  }

  .product-info-main {
    flex: none !important;
    max-width: 100% !important;

    .field.qty {
      display: inline-block;
    }

    .actions {
      display: inline-block;
    }

  }

//  .data.item.title {
//    border-top: 1px solid #e2e2e2 !important;
//    border-left: none !important;
//    border-right: none !important;
//    text-align: left !important;
//    z-index: 1 !important;
//    width: 100% !important;
//
//    a {
//      color: #EF0057 !important;
//    }
//  }
//
//  .data.item.content {
//    border: none !important;
//    display: block !important;
//    padding-top: 0 !important;
//    margin-top: 0 !important;
//  }

  .catalog-products {
    flex-basis: initial !important;

    .product-item {
      width: 100% !important;

      .product-item-info {
        width: 100% !important;
      }
    }

    .product.actions.product-item-actions {
      display: block;
    }

    .toolbar {
      margin: 0 !important;

      .action {
        padding: 0 5px;
      }

      .toolbar-sorter {
        display: inline-block;
      }
      .toolbar-amount {
        display: inline-block;
      }
    }
  }

  .category-view {
    min-height: 1px !important;

    .page-title-wrapper {
      font-size: 15px;
      margin: 0 !important;
    }

    .category-description {
      font-size: 14px !important;
    }

    .category-image {
      transform: none !important;
    }
  }

  .filter-options-content {
    display: none;
  }

  .account-main, .side-account {
    flex: none;
    padding: 0 10px;
    max-width: 100%;
  }


  .block.filter.col-3 {
    max-width: 100%;
    flex: none;
    margin-bottom: 0 !important;
  }

  .block-new-products {
    .product-item {
      width: 100% !important;
      text-align: center;

      .product-item-details {
        vertical-align: top;
      }

    }

  }

  .socialBlock__linksTitle {
    margin-top: 300px !important;
  }

  .social-articles {
    margin-bottom: 40px;
  }

  .instagram-image {
    margin-right: 10px !important;

    &--half {
      width: 100px !important;
    }

    &--full {
      width: 200px !important;
    }

  }

  .category-list-widget {
    margin-bottom: 0 !important;

    .catalog-category__title {
      font-size: 28px;
    }

    .catalog-category {

      display: block !important;

      .category {
        width: 100% !important;
        display: block !important;

        .category__content {
          position: initial;
          margin-left: 0;
          text-align: center;

          h3 {
            font-size: 25px;
          }
        }

      }

      .category-counter {
        writing-mode: horizontal-tb;
        transform: none;
        width: auto;
        top: -42px;
      }
    }
  }

  .footer-title {
    cursor: pointer;

    &:after {
      font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
      content: '\f2f9';
      position: relative;
      font-size: 31px;
      color: #FFC600;
      float: right;
    }
  }

  .footer-title + ul {
    display: none;
  }

  #shopping-cart-table {
    .product-item-photo {
      position: relative;
    }

    .item {
      border-top: 1px solid #d1d1d1;
    }

    .product-item-details {
      padding-top: 20px;
    }

    .item-info {
      .col {
        border-right: none !important;
      }
    }

  }
  .mpblog-post-index {
    .post-list-item {
      .post-image {
        width: 100% !important;
      }
      .post-info-wraper {
        width: 100% !important;
      }
    }
  }

  .cart-container {
    .action {
      margin: 0 !important;
    }
  }
}
@media only screen and (max-width: 496px) {
  .coupon {
    .field {
      text-align: center;
      .label {
        margin-bottom: 0 !important;
      }
    }

  }
}
@media only screen and (max-width: 450px) {
  .responsive-banner {
    display: none !important;
  }
  .mobile-jumbotron {
    display: block !important;
    width: 100%;
  }

  .block.block-minicart.empty.ui-dialog-content.ui-widget-content {
    right: -30px;
    width: 320px;
  }
}

