$break-middle: 1800px;
$break-large: 2200px;
$break-small: 1600px;

.responsive-banner{
    display: none;
    width: 100%;
    @media screen and (max-width: $break-small) {
        display: block;
    }
}

.banner {
    position: relative;
    overflow: hidden;
}

.mobile-jumbotron {
    display: none;
}

.jumbotron-img {
    width: 100%;
    height: 762px;
    @media screen and (max-width: $break-small) {
        display: none;
    }
}
.banner-column {
    position: absolute;
    z-index: 0;
    width: 380px;
    bottom: 23%;
    left: 18%;
    z-index: 20;
    @media screen and (max-width: $break-large) {
        left: 10%;
    }
    @media screen and (max-width: $break-middle) {
        left: 5%;
    }
    @media screen and (max-width: $break-small) {
        display: none;
    }
}
.banner-column2 {
    position: absolute;
    right: 10%;
    @media screen and (max-width: $break-large) {
        right: 5%;
    }
    @media screen and (max-width: $break-middle) {
        right: 0;
    }
    @media screen and (max-width: $break-small) {
        display: none;
    }
}

.banner-column2 .red1 {
    position: absolute;
    right: 817px;
}

.banner-column2 .red2 {
    position: absolute;
    left: 60px;
}

.banner-column2 .banner-background {
    position: absolute;
    right: 6px;
}

.banner-column2 .board-black {
    transform: rotate(-40deg);
    position: relative;
    z-index: 10;
    width: 235px;
    top: 140px;
    right: 262px;
    filter: drop-shadow(5px 5px 12px #222);
}
.banner-column2 .board-blue {
    transform: rotate(-40deg);
    position: relative;
    z-index: 9;
    width: 261px;
    right: 500px;
    bottom: 110px;
}
.banner-column2 .one-atr{
    transform: rotate(49deg);
    font-size: 42px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    font-family: "futura-pt";
    right: 45px;
    top: 35px;
}
.banner-column2 .one-timebomb {
    transform: rotate(49deg);
    font-size: 42px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    left: 310px;
    font-family: "futura-pt";
}
.shop-now {
    display: inline-block;
    z-index: 10;
    float: right;
    padding: 23px 24px 0 0;
    cursor: pointer;
}
.wakeboards {
    display: inline-block;
    border: 5px solid $lightRed;
    width: 382px;
    font-family: "futura-pt";
}
.wakeboards .ronx {
    border: 1px solid $lightRed;
    padding: 16px 23px;
}
.wakeboards .ronx img {
    width: 100%;
}
.wakeboards .wakeboards-text {
    border: 1px solid $lightRed;
    background: $lightRed;
    color: white;
    font-size: 49px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
}
.wakeboards .wakeboards-promo {
    border: 1px solid $lightRed;
    Font-Size: 42px;
    text-align: center;
}
.wakeboards span.banner-date {
    color: $grey;
    font-weight: bold;
    Font-Size: 42px;
    text-transform: uppercase;
}
.wakeboards span.collection {
    color: $grey;
    Font-Size: 42px;
    text-transform: uppercase;
}
.wakeboards span.stock {
    color: $lightRed;
    font-weight: bold;
    Font-Size: 49px;
    text-transform: uppercase;
}



