.page-title-wrapper {
  text-align: center;
  margin-top: 10px;

  .page-title {
    display: block !important;
  }
}

.custom-submenu {
  position: fixed;
  z-index: 99;
  width: 100%;
  background: white;
  left: 0;
  right: 0;
  height: auto;
  max-height: 0;
  transition: max-height 0.5s ease-out, padding 0.5s ease-out;
  box-shadow: 0px 65px 69px #8888884d;
  padding: 0px 100px 0px 200px;

  ul {
    list-style: none;
    text-align: left;
    padding: 0;
  }

  a {
    color: #303030;
    line-height: 53px;
  }

  .block-title {
    border: none !important;

    strong {
      border: none !important;
    }
  }

  .product-item {
    width: 100% !important;
    padding: 0 10% !important;
    text-align: center;
  }

  .product-item-name {
    margin: 0;
    line-height: 0;
  }

  .price-box {
    margin: 0;
  }

  > .container-fluid {
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: max-height .5s ease-out, opacity .5s ease-out;

    > ul {
      column-count: 3;
      column-gap: 0;
    }

    > ul > li {
      border-right: 1px solid #e2e2e2;
      overflow: hidden;
      width: 100%;
      vertical-align: bottom;
      border-bottom: 1px solid #e2e2e2;
      padding: 0 20px;
    }

    > ul ul {
        a {
            font-weight: normal;
            font-size: 14px;
            text-transform: none;
        }
    }

    a {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
    }

  }

  .close-submenu {
    opacity: 0;
    background: #FFC600;
    width: 80px;
    height: 80px;
    color: white;
    border-radius: 50%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: -96px;
    display: none;
    a {
      font-size: 23px;
      line-height: 3.5;
    }
  }

  &.active-menu {
    max-height: 1000px;
    border: 1px solid #8888884d;
    padding: 50px 100px 50px 200px;


    > .container-fluid {
      max-height: 1000px;
      overflow: auto;
      opacity: 1;
      padding-bottom: 100px;
    }

    .close-submenu {
      opacity: 1;
      display: inline-block;

      .custom-menu-back {
        position: fixed;
        width: 100%;
        height: 100%;
        background: white;
        opacity: 0.95;
        z-index: -2;
        left: 0;
        transform: translateY(-1.6%);
        box-shadow: inset 0px 65px 69px #8888884d;
      }
    }

  }
}

.menu__item {
  cursor: pointer;
}
