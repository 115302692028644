.page-main {

  .action.tocompare {
    display: none;
  }

  .catalog-products {
    //width: 75%;
    //float: right;
    border-left: 1px solid #e2e2e2;

    .toolbar {
      margin: 0 30px;
      border-bottom: 1px solid #e2e2e2;

      .sorter-options {
        width: 200px;
        border: 1px solid #E2E2E2;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 12px;
        margin: 20px 0;
      }

      .limiter-options {
        width: 100px;
        border: 1px solid #E2E2E2;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 12px;
        margin: 20px 0;
      }

      &:last-child {
        border-top: 1px solid #e2e2e2;
        border-bottom: none;
        padding-top: 25px;
      }

      .toolbar-amount {
        margin-top: 32px;
      }

      .modes {
        margin-top: 30px;
        .modes-mode {
          border: none !important;
        }
      }
    }

    .product-items {
      text-align: center;

      .product-item {

        &:hover {
          box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
        }
        .product-item-info {
          margin: auto;
          
          .actions-primary {
            display: inline-block;
          }

          .swatch-option.color {
            width: 38px;
            height: 30px;
          }
        }

      }
    }
  }

  .block.filter {
    font-family: poppins;
    padding: 20px;
    font-size: 14px;

    .filter-current {
      .action.remove {
        color: #3d85ce !important;
      }
    }

    .block-actions.filter-actions {
      margin: 0;
      .action.clear.filter-clear {
          color: #3d85ce !important;
          padding: 0;
      }
    }

    .block-title {
      display: none;
    }

    .block-subtitle {
      display: none;
    }

    .filter-options-title {
      border-top: 1px solid #e2e2e2;
      font-family: "futura-pt";
      font-size: 18px;
      padding-top: 20px;
      line-height: 2;
      cursor: pointer;

      &:after {
        font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
        content: '\f2f9';
        position: relative;
        font-size: 31px;
        color: black;
        float: right;
      }
    }

    .filter-options-content {

      .swatch-attribute-options {
        margin-top: 0;
      }

      &:last-child {
        border: none;
      }

      .items {
          .item {
              padding-bottom: 4px;
              border-bottom: 1px dotted #e2e2e2;
              margin-bottom: 8px;
              a {
                  color: #303030 !important;
              }
              &:last-child {
                  border-bottom: 0px;
              }
              .count {
                  float:right;
              }
          }
      }

    }

    .swatch-option.color {
      width: 50px;
      height: 50px;
    }

  }

  .sidebar-additional {
    display: none;
  }

  .category-view {
    height: auto;
    min-height: 333px;
    overflow: hidden;
    position: relative;
    text-align: center;
    border-bottom: 1px solid #8888884d;

    .page-title-wrapper {
      margin-bottom: 15px;
      color: #3d85ce !important;

      #page-title-heading {
        margin: 0;
        color: #3d85ce !important;
      }

      svg {
        path {
          opacity: 0.4;
          fill: #3d85ce;
        }
      }
    }

    .category-image {
      position: absolute;
      width: 100%;
      filter: grayscale(100%);
      opacity: 0.2;
      z-index: -1;
      transform: translate(0,-25%);

      img {
        width: 100%;
      }

    }
  }

}
