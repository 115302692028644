.search {
    &--header {
        .input-text {
            background: $lightGrey;
            border: 0px;
            line-height: 70px;
            padding: 0 20px;
            color: #FFF;
            outline: none;
        }
        .control {
            padding-bottom: 0px;
        }
        .nested {
            display: none;
        }
    }
    .block-search {
        width: 100%;
        float: left;
        position: relative;
        padding-left: 0px;
    }
    .input-text {
        width: 100%;
    }
    .actions {
        position: absolute;
        z-index: 1;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        .search {
            position: static;
            padding: 0px;
            &:before {
                color: #FFF;
                font-size: 36px;
                line-height: 36px;
            }
        }
    }

    &.results {
        .actions {
            position: relative;
            transform: none;
        }
    }
}
