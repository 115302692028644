.mpblog-post-index, .mpblog-category-view, .mpblog-author-view, .mpblog-month-view {

    .autocomplete-suggestions {
        background: #FFF;
        border-left: 2px solid #e2e2e2;
        border-bottom: 2px solid #e2e2e2;
        border-right: 2px solid #e2e2e2;
        margin-left: -1px;
        box-shadow: 0px 15px 50px rgba(0,0,0,0.15);
        .autocomplete-suggestion {
            &.autocomplete-selected {
                .mpblog-suggestion {
                    border-bottom: 1px solid black;
                }
            }
            .mpblog-suggestion {
                clear: both;
                border-bottom: 1px solid #f2f2f2;
                padding-top: 10px;
                padding-bottom: 10px;
                cursor: pointer;
                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
                .mpblog-suggestion-left {
                    float: left;
                    width: 30%;
                    img {
                        width: 100%;
                    }
                }
                .mpblog-suggestion-right {
                    float: right;
                    width: 70%;
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }
        }
    }

    .pager {
        min-height: 50px;
        margin-bottom: 30px;
        .limiter {
            @include media-breakpoint-up(md) {
                position: relative;
                top: -50px;
            }
        }
        .pages {
            text-align: center;
        }
    }

  .columns {
  }

  .category__content {
      padding: 0 15px;
  }

  .sidebar.sidebar-main {
  
      .mp-sidebar {
        margin-bottom: 0;
        &.product.info.detailed {
    
            .data {
              margin-bottom: 0;
                &.title {
                    
                    a {
                        color: black !important;
                    }
                    &.active {
                    }
                }
                &.content {
                    padding: 20px 0;
                    .list-post-tabs {
                        padding-bottom: 20px;
                    }
                    .post-left {
                        float: left;
                        width: 30%;
                        img {
                            width: 100%;
                        }
                    }
                    .post-right {
                        float: right;
                        width: 70%;
                        padding-left: 15px;
                        a {
                            color: black;
                            font-weight: bold;
                        }
                        .create-at {
                            clear: both;
                            display: block;
                        }
                    }
                }
            }
    
        }
        &.mpcss {
            .block-content {
                width: 100%;
            }
            .row {
                margin-left: 0px;
                margin-right: 0px;
                .col-xs-12 {
                    width: 100%;
                }
            }
            .block-title {
                width: 100%;
                color: black;
                padding: 10px 0;
                border-bottom: 2px solid black;
                .block-mp-sidebar-heading {
                    margin-bottom: 0px;
                }
            }
            .menu-categories, .menu-archives {
                width: 100%;
                margin: 0px;
                padding: 0px;
                margin-top: 8px;
                .block-content {
                    width: 100%;
                }
                li {
                    list-style: none;
                    padding: 6px 0;
                    border-bottom: 1px solid #f2f2f2;
                    .mp-blog-narrow-tree-2, .mp-blog-expand-tree-2 {
                        margin-right: 10px;
                        color: black;
                    }
                    a {
                        color: #000;
                        i {
                            color: black;
                        }
                    }
                }
                ul {
                    padding-left: 50px;
                    li {
                        border-bottom: 0px;
                    }
                }
                .category-level3 {
                  display: none;
                }
            }

            .tags-list {
                    font-size: 16px !important;
                    color: black;
            }
        }
    }

    .mpblog-search {
      margin-top: 40px;
      margin-bottom: 20px;
      padding-top: 20px;
      border-top: 4px solid black;
      position: relative;
  
      .actions {
          position: absolute;
          right: 0px;
          bottom: 0px;
          button.search {
              background: none;
              border: 0px;
              line-height: 47px;
              padding-right: 20px;
              i {
                  color: black;
              }
          }
      }
  
      label {
          font-size: 28px;
          color: black;
      }
      input[type="text"] {
          width: 100%;
          border: 2px solid #e2e2e2;
          padding: 12px 20px;
          outline: none;
      }
  
  }
  }

  .main {

  }

  .sidebar.sidebar-additional {
    display: none;
  }

  .post-list-body {
    margin-top: 30px;
    margin-bottom: 30px;
    border-top: 1px solid #e2e2e2;
    padding-top: 40px;
  }

  .post-list-item {
    margin-top: 30px;
    margin-bottom: 30px;

    .post-item-wraper {
        display: table;
    }

    .post-image, .post-info-wraper {
        display:table-cell;
        vertical-align: middle;
        width: 45%;
        @include media-breakpoint-down(md) {
            width: 100%;
            display: block;
        }
    }

    .post-link-title {
      font-size: 36px;
      color: $yellow !important;
    }

    .post-info-wraper {
        @include media-breakpoint-up(lg) {
            padding-left: 30px;
        }
        .mp-post-info {
            margin-bottom: 20px;
            font-size: 14px;
            color: #707070;
        }
    }

    .post-image {
      img {
        width: 100%;
      }
    }

    .action {
      display: inline-block;
      background: #FFEAA1;
    }
  }
}

.mpblog-post-view {

    .category__content {
        padding: 0 15px;
    }

    .category__products {
        flex: 0 0 100%;
        max-width: 100%;

        .post-post_content {
            img {
                max-width: 100%;
                object-fit: contain;
            }
        }
    }

    .category__sidebar {
        display: none;
    }

    .page-title {
        color: $yellow;
    }

  .sidebar {

  }

  .mp-blog-view {
    margin: 30px auto;
    max-width: 900px;

        .post-view-image {
            img {
                width: 100%;
                height: auto;
            }
        }

      .post-post_content {
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: 30px;
        p {
            line-height: 21px;
        }
      }
  }
}
.mp-footer {
   display: none;
 }
