header.page-header {
    background: $grey;
    height: 70px;
    .panel.wrapper {
        display: none;
    }
    .minicart-wrapper {
        margin-left: 25px;
        line-height: 70px;
        display: inline-block;
        .action.showcart {
            outline: none;
            position: relative;
            font-family: "poppins";

            .counter {
                position: absolute;
                right: 14px;
                bottom: 14px;
                &.qty {
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    border-radius: 18px;
                    background: #FFF;
                    color: $grey;
                    font-size: 9px;
                    margin-top: 0px;
                    &.empty {
                        display: inline-block;
                    }
                }
            }

            &:before {
                display: none;
            }
            &.active {
                &:before {
                    display: none;
                }
            }
        }
        a {
            color: #FFF;
            font-size: 14px;
            i {
                font-size: 28px;
                color: $yellow;
                vertical-align: middle;
                margin-top: -2px;
                margin-left: 8px;
            }
        }
    }
}

.logo {
    &--header {
        margin: 0;

        .logo {
            display: block;
            max-width: none;
            margin: 0px;
            img {
                max-width: 100%;
                height: auto;
                line-height: 70px;
                margin-top: 20px;
            }
        }
        .nav-toggle {
            display: none;
        }

    }
}

.page-header {
    &__account {
        text-align: right;
    }
}

.header {
    &.links {
        margin: 0px;
        padding: 0px;
        display: inline-block;
        li {
            list-style: none;
            display: inline-block;
            line-height: 70px;
            a {
                color: #FFF;
                font-size: 14px;
                i {
                    font-size: 28px;
                    color: $yellow;
                    vertical-align: middle;
                    margin-top: -2px;
                    margin-left: 8px;
                }
            }
        }
    }
}
