.loader > img {
  max-width: 18px !important;
}

.block-minicart {
  font-family: futura-pt;
  font-weight: bold;

  .minicart-items-wrapper {
    border: none;
  }

  .product.actions {
    position: relative;
    top: -60px;
    left: 45px;

    a {
      line-height: 0;
    }

    .primary {
      margin: 0;
      display: block !important;
    }

    .secondary {
      display: block !important;
    }

  }

  .product-item {
    border:none !important;
    padding: 0 !important;
  }

  .product-item-details {
    line-height: 1 !important;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    margin-right: 45px;

    .product-item-name {
      font-size: 20px;
      font-weight: bold;

      a {
        color: #3d85ce;
      }
    }

    input[type=number] {
      -moz-appearance:textfield;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .product.options.list {
      margin-bottom: 0;
    }

    .update-cart-item {
      background: none;
      border: none;

      span {
        font-size: 15px;
      }
    }
  }

  .items-total {
    display: none;
  }

  .label {
    display: none;
  }

  button {
    font-size: 18px !important;
  }
  
  .toggle {
    display: none !important;
  }
  
  .content {
    display: inline !important;
  }

  .details-qty {
  }

  #top-cart-btn-checkout {
    &:after {
      background: #DDEDBB !important;
    }
  }
  .subtotal {
    float: left;
  }

  .actions {
    margin-top: 0;
  }

  .primary {
    float: right;
    margin: 0 24px 0;
  }

  .secondary {
    display: block;
    line-height: 0;
    clear: both;

    a {
      color: #212529 !important;
      line-height: 0;
    }
  }

  .minicart-items {
    border-bottom: 1px solid #E2E2E2;
  }
  
}