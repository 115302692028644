.perk-band {
    background: $lightYellow;
    ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
    }
    li {
        display: inline-block;
        margin: 0px;
        padding: 15px 0px;
        margin-left: -5px;
        width: 25%;
        font-size: 14px;
        &:first-child {
            margin-left: 0px;
        }
    }

    &--narrow {
        li {
            vertical-align: middle;
            text-align: center;
        }
    }

    &--tall {
        padding-top: 30px;
        padding-bottom: 30px;
        li {
            vertical-align: top;
            line-height: 30px;
        }
        p {
            margin-top: 15px !important;
            font-family: "poppins";
            font-size: 12px;
            line-height: 1.8;
            padding-right: 30px;
        }
    }

}
