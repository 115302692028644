.category-list-widget {
    overflow: hidden;
    margin-bottom: 60px;
    margin-top: 60px;
}

.catalog-category {
    min-height: 600px;
    &__title {
        font-size: 42px;
        color: #3d85ce;
        margin-bottom: 30px;
    }
    &__navigation {
        line-height: 42px;
        text-align: right;
        i {
            font-size: 42px;
            cursor: pointer;
            color: $lightGrey;
            &:hover {
                color: $blue;
            }
            &:last-child {
                margin-left: 30px;
            }
        }
    }
}

.category-counter {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    i {
        transform: rotate(90deg);
    }

    &__all, &__count {
        padding: 0 15px;
        color: $lightGrey;
    }

    &__all {
        float: left;
    }

    &__count {
        float: right;
        .current {
            color: $blue;
        }
    }

}

.catalog-category {

    .category {
        float: left;
        width: 43%;
        height: 100%;
        position: relative;
        margin-right: 30px;

        @media(min-width: 1400px) {
            width: 36%;
        }

        @media only screen and (min-width: 769px) {

            &:first-child {
                .category__image {

                    height: 100%;
                    overflow: hidden;
                    position: relative;

                    img {
                        position: absolute;
                        height: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: auto;
                        max-height: 1000px;
                    }
                }
            }
        }

        &__image {
            width: 100%;
            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                max-height: 290px;
            }
        }

        &__content {
            width: 100%;
            position: absolute;
            left: 100%;
            margin-left: 30px;
            h3 {
                font-size: 42px;
                color: $grey;
            }
            p {
                color: $grey;
                font-size: 14px;
                line-height: 1.75;
                margin-bottom: 20px;
            }
            .action {
                display: inline-block;
            }

            a{
                position: relative;
                z-index: 9;
            }
        }

        &:nth-child(odd) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .category {
                &__content {
                    top: 0px;
                }
            }
        }

        &:nth-child(even) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .category {
                &__content {
                    top: 50%;
                }
            }
        }

    }

}
.message.success {
    margin: 0;
}
