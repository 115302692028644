.action {
    position: relative;
    line-height: 60px;
    padding: 0 20px;

    &:hover {
        text-decoration: none;
    }

    &.primary {
        color: $grey;
        background: $lightYellow;
    }

    &.secondary {
        color: $grey;
        background: $lightBlue;
    }
    &.banner {
        color: $grey;
        font-size: 24px;
        background: $turquoise;
        height: 62px;
        top: -15px;
        padding: 12px 20px;
    }
}

button.action {
    background: none;
    border: 0px;
}
