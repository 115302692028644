// Mixins
@import "mixins/media-queries";

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI
@import 'vendor/google-icons/icons';

// Theme variables
@import 'variables';

// Bootstrap
@import "bootstrap/bootstrap-functions";
@import "bootstrap/bootstrap-variables";
@import "bootstrap/bootstrap-mixins";
@import "bootstrap/bootstrap-utilities";
@import "bootstrap/bootstrap-grid";
@import "bootstrap/bootstrap-reboot";

@media (min-width: 1400px) {
    .container {
        max-width: 1340px;
    }
}

// Responsive
@import "responsive/responsive";


// Theme blocks
@import 'blocks/actions-toolbar';
@import 'blocks/breadcrumbs';
@import 'blocks/buttons';
@import 'blocks/extends';
@import 'blocks/forms';
@import 'blocks/icons';
@import 'blocks/layout';
@import 'blocks/loaders';
@import 'blocks/category-page';
@import 'blocks/product-page';
@import 'blocks/basket';
@import 'blocks/checkout-page';
@import 'blocks/onepage-checkout';
@import 'blocks/messages';
@import 'blocks/navigation';
@import 'blocks/account';
@import 'blocks/generic';
@import 'blocks/pages'; // Theme pager
@import 'blocks/popups';
@import 'blocks/price';
@import 'blocks/sections';
@import 'blocks/tables';
@import 'blocks/tooltips';
@import 'blocks/blog';
//@import 'blocks/typography';

// Components styles (modal/sliding panel)
@import 'blocks/components/modals'; // from lib
@import 'blocks/components/modals_extend'; // local

// Modules
@import '../Magento_AdvancedCheckout/styles/module';
@import '../Magento_Braintree/styles/module';
@import '../Magento_Bundle/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_CatalogEvent/styles/module';
@import '../Magento_CatalogSearch/styles/module';
@import '../Magento_Checkout/styles/module';
@import '../Magento_Customer/styles/module';
@import '../Magento_Downloadable/styles/module';
@import '../Magento_GiftCard/styles/module';
@import '../Magento_GiftCardAccount/styles/module';
@import '../Magento_GiftMessage/styles/module';
@import '../Magento_GiftRegistry/styles/module';
@import '../Magento_GiftWrapping/styles/module';
@import '../Magento_GroupedProduct/styles/module';
@import '../Magento_Invitation/styles/module';
@import '../Magento_LayeredNavigation/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../Magento_MultipleWishlist/styles/module';
@import '../Magento_Multishipping/styles/module';
@import '../Magento_Newsletter/styles/module';
@import '../Magento_Paypal/styles/module';
@import '../Magento_ProductVideo/styles/module';
@import '../Magento_Review/styles/module';
@import '../Magento_Reward/styles/module';
@import '../Magento_Rma/styles/module';
@import '../Magento_Sales/styles/module';
@import '../Magento_SalesRule/styles/module';
@import '../Magento_SendFriend/styles/module';
@import '../Magento_Swatches/styles/swatches';
@import '../Magento_Theme/styles/module';
@import '../Magento_Vault/styles/module';
@import '../Magento_Weee/styles/module';
@import '../Magento_Wishlist/styles/module';


// Widgets
@import '../Magento_AdvancedCheckout/styles/widgets';
@import '../Magento_Banner/styles/widgets';
@import '../Magento_Catalog/styles/widgets';
@import '../Magento_CatalogEvent/styles/widgets';
@import '../Magento_Cms/styles/widgets';
@import '../Magento_MultipleWishlist/styles/widgets';
@import '../Magento_VersionsCms/styles/widgets';

// Custom css
//@import url("pages/product-page.css");

// Vendor
@import "flickity/flickity";

// Blocks
@import 'blocks/search';
@import 'blocks/perks';
@import 'blocks/jumbotron';
@import 'blocks/categoryList';
@import 'blocks/socialWidget';
@import "blocks/submenu";

// Sections
@import 'sections/header';
@import 'sections/footer';

// Hamburger
@import "node_modules/hamburgers/_sass/hamburgers/hamburgers";

body {
    font-family: "poppins";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    h1,h2,h3,h4,h5,h6 {
        font-family: "futura-pt";
    }
    /*
        @include media-breakpoint-up (sm) {
            border-top: 5px solid red;
        }

        @include media-breakpoint-up (md) {
            border-top: 5px solid blue;
        }

        @include media-breakpoint-up (lg) {
            border-top: 5px solid green;
        }

        @include media-breakpoint-up (xl) {
            border-top: 5px solid pink;
        }
    */
}

.block-static-block.widget {
    margin-bottom: 0px;
}

.block, .widget {
    &.grid {

        .block-title {
            border-bottom: 1px solid #f2f2f2;
            font-family: "futura-pt";
            font-size: 36px;
            strong {
                padding: 20px 30px;
                display: inline-block;
                margin: 0px;
                border: 1px solid #f2f2f2;
                border-bottom: 1px solid #FFF;
                margin-bottom: -1px;
                margin-left: 15px;
            }
        }

    }
}

.block-new-products {
    .block-title {
        color: $red;
    }
}

.abs-product-link, .product-item-name, .product.name a {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
}

.product-info-stock-sku .block-static-block {
    display: none;
}

.old-price {
    .price-final_price, .price {
        font-size: 14px !important;
    }
}

.special-price {
    margin-bottom: 8px !important;

    .percent-saving {
        display: inline-block;
        padding: 0;
        margin-left: 0;
        vertical-align: top;
        color: #303030;
        z-index: -1;
        font-weight: 700;
    }

    .price-label {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        color: #EF0057;
    }

    .price-wrapper {
        color: #EF0057;
        padding: 0px;
    }
}

.normal-price {
    .price-label {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        color: #EF0057;
    }
}

.catalog-category-view {
    .special-price {
        .price-wrapper {
            font-size: 14px;
            padding: 0px;
        }
        .percent-saving {
            font-size: 14px;
            padding: 2px 0;
        }
    }
}

.checkout-billing-address {
    padding: 20px;
    border: 1px solid #e2e2e2;
    background: #f2f2f2;
    margin-top: 20px;
    padding-top: 25px;

    #billing-address-same-as-shipping {
        margin-right: 8px;
    }
    .step-title {
        margin-bottom: 30px;
    }
    .input-text {
        padding: 0px 12px;
    }
}

#checkout-step-payment {
    .checkout-billing-address {
        display: none;
    }
}

.payment-method {

    .payment-method-title {
        input[type="radio"] {
            margin-right: 8px;
        }
    }

    .payment-icon {
        margin-left: 10px;
    }
    .action.action-help {
        display: none;
    }
}

.checkout-container .opc-progress-bar-item._complete span {
    color: #303030;
}

.checkout-container #checkout-step-shipping .label:before, .checkout-container .form-login .label:before, .checkout-container .payments .label:before {
    content: '';
}

.page-layout-checkout {
    .field._required {
        .label:before {
            content: '*' !important;
        }
    }
}

.category-description {
    img {
        width: auto;
        height: auto;
        max-width: 100%;
    }
}

#bundle-slide {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
}

.bundle-options-wrapper {
    .product-options-wrapper {
        width: 100%;
    }
    padding: 30px;
    border-top: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
    .fieldset-bundle-options {
        .legend.title {
            font-size: 22px;
            text-transform: uppercase;
            font-weight: 900;
            line-height: 150%;
            margin-top: 0px;
            color: #EF0057;
        }
        .action.back.customization {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
            background: #FFEAA1;
            clear: both;
            display: block;
        }
        .field {
            padding: 10px;
            &.option {
                background:#fff;
                box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
                padding: 20px;
                width: 49%;
                float: left;
                &:nth-child(even) {
                    margin-right: 2%;
                }
            }
            .qty-holder {
                background: #f2f2f2;
            }
            .qty {
                label, .control {
                    display: inline-block;
                }
                .input-text.qty {
                    padding: 8px 12px;
                    width: 80px;
                    margin-left: 20px;
                }
            }
        }
    }
}

#bundleSummary {
    padding: 30px;
    background: #f2f2f2;
    border: 1px solid #e2e2e2;
    .title {
        color: #EF0057 !important;
        font-size: 36px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e2e2e2;
        strong {
            font-weight: 700 !important;
        }
    }
    .bundle-info {
        .product-image-container {
            margin-right: 30px;
        }
        .product-image-container, .product-details {
            float: left;
        }
        .product-details {
            .action.primary.tocart {
                text-transform: uppercase;
                font-weight: 900;
                font-size: 16px;
            }
        }
        .qty {
            label {
                display: inline-block;
            }
            .control {
                display: inline-block;
                input[type="number"] {
                    padding: 8px 12px;
                    width: 80px;
                    margin-left: 20px;
                }
            }
        }
    }
    .bundle-summary {
        clear: both;
        background: #fff;
        padding: 30px;
        background: #fff;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
        .subtitle {
            margin-top: 0px;
            color: #EF0057 !important;
            padding-bottom: 10px;
            border-bottom: 1px solid #e2e2e2;
            strong {
                font-weight: 700 !important;
            }
        }
    }
}

.block-search {
    z-index: 999;
}

.mst-searchautocomplete {
    &__autocomplete {
        position: fixed;
        width: 100% !important;
        background: #ffffff;
        left: 0px !important;
        z-index: 999;
        display: none;
        padding-top: 20px;
        padding-bottom: 20px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
        max-height: calc(100% - 70px);
        @media (max-width: 1140px) {
            top: 140px !important;
        }
        &._active {
            display: block;
            overflow: auto;
        }
    }
}

.mst-searchautocomplete__close {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #e2e2e2;
    border-radius: 50px;
    text-align: center;
    margin: 0 auto;
}

.mst-searchautocomplete__index {
    &.magento_catalog_categoryproduct {
        width: 33.333%;
        display: inline-block;
        vertical-align: top;
        padding: 30px;

        @media(max-width: 991px) {
            width: 100%;
        }

        ul {
            margin: 0px 15px;
            padding: 0px;
            list-style: none;
            li {
                padding: 15px 0;
                border-bottom: 1px solid #e2e2e2;
            }
        }

    }
    &.magento_catalog_product {
        width: 66.666%;
        display: inline-block;
        vertical-align: top;
        padding: 30px;
        border-left: 1px solid #e2e2e2;
        margin-left: -5px;

        @media(max-width: 991px) {
            width: 100%;
        }

        ul {
            padding: 0px;
            margin: 0px;
            list-style: none;
            li {
                width: 25%;
                display: inline-block;
                margin-left: -4px;
                padding: 0 15px;
                vertical-align: top;

                @media (max-width: 1400px) {
                    width: 33.333%;
                }

                @media(max-width: 1140px) {
                    width: 50%;
                }

                img {
                    width: 100%;
                    height: auto;
                }
                .title {
                    font-size: 22px;
                    padding-top: 15px;
                    color: #000000;

                    @media(max-width: 991px) {
                        font-size: 16px;
                    }

                }
                .description {
                    display: none;
                }
                .price {
                    margin-bottom: 20px;
                }
            }
        }

    }
}

.mst-searchautocomplete__index-title {
    margin: 0 15px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;
}

.mst-searchautocomplete__wrapper {
    border-top: 1px solid #e2e2e2;
    margin-top: 20px;
}

.searchautocomplete__active {
    overflow: hidden;
}
.mst-searchautocomplete__show-all {
    text-align: center;
    padding: 20px 0;
    a {
        background: #303030;
        padding: 20px 30px;
        border-radius: 500px;
        color: #fff;
        &:hover {
            color: $yellow;
        }
    }
}
.mst-searchautocomplete__empty-result {
    text-align: center;
    padding-top: 20px;
}

.mst-searchautocomplete__index.popular {
    display: none;
}














/*###################*/
/*Identify Styling*/
/*###################*/

//body {
//  background-color: black !important;
//}

//Desktop
.mobile-hide {
    display: block !important;
}

.mobile-show {
    display: none !important;
}

.float-left {
    float: left !important;
}

.w-100 {
    width: 100% !important;
}

.action.primary {
    background: #f8c602;
    line-height: 1.5;
    padding: 9px 20px;
}

.catalog-category-view .special-price .percent-saving:before, .products-related .special-price .percent-saving:before, .catalog-product-view .special-price .percent-saving:before {
    content: "(";
}

.catalog-category-view .special-price .percent-saving:after, .products-related .special-price .percent-saving:after, .catalog-product-view .special-price .percent-saving:after {
    content: ")";
}

.product-item .price-box .price-label, span.price-label {
    font-size: 0;
}

.products-related .special-price .percent-saving {
    font-size: 14px;
    padding: 2px 0;
}

.product-info-main span.old-price {
    margin: 0 0 12px;
    float: left;
}

.product-info-main .page-title-wrapper {
    margin-bottom: 10px;
}

.product-item .old-price .price {
    font-weight: 700;
}

//####################
// Identify
//New category styling
//####################

.category-top {min-height: auto !important;border: none !important;padding-top: 70px;    margin: 0 0 74px;}

.category-top .breadcrumbs {padding: 0;margin: -2px 0 24px;}

.category-top .page-title-wrapper {margin: 0 0 48px !important;float: left;width: 100%;}

.category-top h1 {text-align: left;float: left;width: 100%;font-size: 40px;}

.category-top .breadcrumbs strong {font-size: 15px;font-weight: 900;}

.category-top .breadcrumbs .home a {padding-left: 0;}

.category-top .category-description .container {padding: 0;}

.category-top .category-description, .category-top .category-description p {
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    margin: 0;
}

body.page-layout-2columns-left .category-top .category-description, .category-top .category-description p {
    text-align: center;
}

.category-top .category-description {margin: 0 0 40px;}

.page-layout-category-landing-page .generic-page.container {padding: 0 15px;}

.page-layout-category-landing-page .category-top .category-description {margin: 0 0 0px 0;}

.page-layout-2columns-left .category-top h1 {
    text-align: center;
}

.child-categories .row {margin-right: -25px;
    margin-left: -25px;}

.child-categories .category-box-wrapper {padding: 0 25px;margin-bottom: 50px;}

.category-box {overflow: hidden;border: 1px solid #e8e8e8;padding: 30px;display: flex;height: 100%;align-items: center;}

.category-box .image-container {margin: 0 25px 0 0;}

.category-box img {float: left;width: auto;height: auto;max-width: 90px;max-height: 95px;margin: 0;}

.category-box .category-text strong {font-size: 14px;line-height: 28px;color: #303030;font-weight: 600;opacity: 0.52;letter-spacing: 0.22em;}

.category-box h4 {margin: 0 !important;font-size: 20px;line-height: 28px;color: #303030;font-weight: 700;letter-spacing: -0.02em;}

.category-box .category-text {float: left;width: 100%;display: flex;flex-wrap: wrap;align-content: center;}

.category-box:hover {background: #e8e8e8;}

.category-box:hover .category-text strong, .category-box:hover h4 {opacity: 1;color: #3d85ce;}

.child-categories {margin: 0 0 37px;}

.split-blocks {margin-bottom: 90px;}

.split-blocks .block-container {height: 545px;padding: 60px;display: flex;align-content: flex-end;flex-wrap: wrap;cursor: pointer;}

.split-blocks img {top: 0;left: 0;object-fit: cover;}

.split-blocks h3, .split-blocks .content, .split-blocks .action {position: relative;z-index: 2;}

.split-blocks h3 {font-size: 34px;font-weight: 900;line-height: 22px;margin: 0 0 33px;color: white;}

.split-blocks .content {margin: 0 0 50px;}

.split-blocks p, .single-accordion p, .background-image-block p {font-size: 16px;line-height: 32px;font-weight: 400;color: white;}

.content>*:last-child {
    margin-bottom: -11px;
}

.content>*:first-child {
    margin-top: -9px;
}

.split-blocks .block-container:before, .background-image-block:before {background: #0000001f;}

.split-blocks .block-container:after, .background-image-block:after {/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,000000+100&0+0,1+100 */
    background: -moz-linear-gradient(top,  rgba(30,87,153,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(30,87,153,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(30,87,153,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#000000',GradientType=0 ); /* IE6-9 */;opacity: 0.3;}

.split-blocks .block-container:before, .split-blocks .block-container:after, .background-image-block:before, .background-image-block:after {content: "";
    height: 100%;
    width: 100%;position: absolute;
    top: 0;
    left: 0;
    z-index: 1;}

.split-blocks .block-container:hover:after {opacity: 0.6;}

.standard-content {margin-bottom: 65px;}

.content, .page-layout-category-landing-page ol.products {float: left;width: 100%;}

.standard-content h1 {font-size: 40px;}

.standard-content h2 {font-size: 38px;}

.standard-content h3 {font-size: 36px;}

.standard-content h4 {font-size: 34px;}

.standard-content h5 {font-size: 34px;}

.standard-content h6, .standard-content h7, .standard-content h8 {font-size: 30px;}

.standard-content h1, .standard-content h2, .standard-content h3, .standard-content h4, .standard-content h5, .standard-content h6, .standard-content h7, .standard-content h8 {color: #3d85ce;line-height: 1.2;margin: 0 0 37px;}

.standard-content p {color: #212529;font-size: 16px;line-height: 32px;font-weight: 300;}

.accordions-block {margin-bottom: 55px;}

.single-accordion {margin: 0 0 35px;padding: 0 0 35px;border-bottom: 1px solid #e2e2e2;}

.single-accordion h6 {font-size: 24px;color: #030303;line-height: 22px;margin: -2px 0 0;position: relative;cursor: pointer;}

.single-accordion p {color: #212529;}

.single-accordion .content {display: none;margin: 27px 0 0;}

.single-accordion h6:after {font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    content: '\f2fb';font-size: 22px;float: right;transform: rotate(90deg);-webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;}

.single-accordion h6:hover:after, .single-accordion.active h6:after {transform: rotate(270deg);}

.single-accordion.active {border-color: #030303;}

.background-image-block {margin: 0 0 15px;height: 490px;float:left;width: 100%;}

.background-image-block img {top: 0;left: 0;object-fit: cover;}

.background-image-block .col-12 {display: flex;flex-wrap: wrap;align-content: flex-end;padding: 0 60px 77px;}

.background-image-block h4 {font-size: 34px;font-weight: 900;line-height: 22px;margin: 0 0 40px;}

.background-image-block .content {margin: 0 0 47px;}

.background-image-block h4, .background-image-block p, .background-image-block p a {color: white;}

.background-image-block p a, .background-image-block p strong {font-weight: 600;position: relative;text-decoration: none !important;}

.background-image-block p a:before, .background-image-block p a:after {content: "";position: absolute;bottom: 0;left: 0;background: white;width: 100%;height: 2px;;-webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;}

.background-image-block p a:after {left: auto;right: 0;}

.background-image-block p a:hover:before, .background-image-block p a:hover:after {width: 0;}

.background-image-block h4, .background-image-block .content, .background-image-block .action {position: relative;z-index: 2;}

.page-layout-category-landing-page .products h3 {font-size: 34px;line-height: 22px;font-weight: 900;color: #3d85ce;margin: 0 0 55px;}

.page-layout-category-landing-page ol.products {margin: 0 0 53px !important;}

.page-layout-category-landing-page ol.products button.next {right: -10px;}

.page-layout-category-landing-page ol.products button.previous {left: auto;right: 26px;}

.page-layout-category-landing-page ol.products button.previous, .page-layout-category-landing-page ol.products button.next {top: -65px;}

.page-layout-category-landing-page ol.products .product.photo {float: left;width: 100%;height: 245px;margin: 0 0 37px;border: 1px solid #e2e2e2;}

.page-layout-category-landing-page ol.products .product.photo img {float: left;width: 100%;height: 100%;margin: 0;object-fit: contain;}

.page-layout-category-landing-page ol.products .product-image-container {float: left;width: 100%;height: 100%;}

.page-layout-category-landing-page ol.products .product-image-wrapper {float: left;width: 100%;padding: 0 !important;height: 100%;}

.page-layout-category-landing-page ol.products .product.details {float: left;width: 100%;display: flex;flex-wrap: wrap;justify-content: center;}

.page-layout-category-landing-page ol.products strong.product {float: left;width: 100%;text-align: center;margin: 0 0 8px;}

.page-layout-category-landing-page ol.products .product-item-link {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #303030;
    text-decoration: none !important;
    letter-spacing: -0.02em;
}

.page-layout-category-landing-page ol.products .product-item-link:hover {color: #3d85ce;}

.page-layout-category-landing-page ol.products .price-box {float: left;width: 100%;margin: 0 0 30px;display: flex;align-items: flex-end;justify-content: space-around;}

.page-layout-category-landing-page ol.products .price-box .old-price {order: -1;color: #fe4545;font-size: 14px;line-height: 15px;text-decoration: line-through;}

.page-layout-category-landing-page ol.products .price-box .special-price {margin: 0 !important;}

.page-layout-category-landing-page ol.products .price-box .special-price .price-wrapper {font-size: 20px;color: #212529;font-weight: 700;line-height: 20px;}

.page-layout-category-landing-page ol.products .price-box .special-price .percent-saving {padding: 0;font-size: 14px;color: #83888d;font-weight: 500;}

.page-layout-category-landing-page ol.products .price-box .special-price .price-wrapper .price {font-size: 20px;}

.page-layout-category-landing-page ol.products .price-box .old-price .price, .page-layout-category-landing-page ol.products .price-box .special-price .percent-saving {font-size: 12px !important;}

.page-main .category-view .category-image {
    top: 0;
    left: 0;
}

.page-main .block.filter .filter-options-title {
    padding: 12px 0 0;
}

.product-image-photo, .fotorama__img {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain !important;
}

.giftcard-template-preview {
    float: left;
    transform: none !important;
    height: auto !important;
}

.giftcard-template-container-preview, div#giftcard-template-container, div#giftcard-template-container > div {
    float: left;
}

.preview-giftcard {
    float: left;
    position: relative;
}

.giftcard-template-setting, .giftcard-information-delivery-content .field, .giftcard-information-delivery-content .field label, .giftcard-information-delivery-content .field input, .giftcard-information-delivery-content .field textarea, .giftcard-template-container-preview {
    float: left;
    width: 100% !important;
}

ul.giftcard-amount, ul.giftcard-delivery {
    float: left;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.giftcard-field-label {
    float: left;
    width: 100%;
    margin: 0 0 5px;
    font-weight: 700;
    color: #212529;
}

.giftcard-template-setting {
    margin-top: 20px;
}

.giftcard-template-choose-images {
    margin: 10px 0 0;
}

//####################
// Identify
// End New category styling
//####################

//Whats up in the hood block
.whats-up-in-the-hood {
    margin-bottom: 80px;
}

.whats-up-in-the-hood h2 {
    color: #f8c603;
    margin: -10px 0 40px;
    font-size: 32px;
}

.archive-link, .other-articles a {
    color: #3d85ce !important;
    text-decoration: none !important;
    box-shadow: inset 0 -0.1rem 0 0 #3d85ce;
}

.archive-link:hover, .other-articles a:hover {
    box-shadow: inset 0 0 0 0 transparent;
}

.archive-link, .other-articles a {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.featured-article {
    padding-right: 40px;
}

.featured-article .image-container {box-shadow: 0px 0px 35px 0px #0000004d;margin-bottom: 30px;}

.featured-article img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.featured-article h3 {
    color: #3d85ce;
    font-size: 22px;
    margin: 0 0 20px;
}

.featured-article p {
    line-height: 32px;
}

.featured-article a.action {
    margin: 10px 0 0;
    float: left;
}

.featured-article a.action:hover {
    background: #bbc9ed;
}

.other-articles {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e2e2e2;
    padding-left: 140px;
    min-height: 120px;
    display: flex;
    align-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

//.other-articles:last-of-type {
//    margin-bottom: 0;
//    padding-bottom: 0;
//    border: none;
//}

.other-articles .image-container {
    width: 90px;
    height: 90px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 35px 0px #0000004d;
}

.other-articles img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}

.other-articles h3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
//Whats up in the hood end



.fotorama__thumb {
    display: flex;
}
.fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
    width: 100% !important;
    height: auto !important;
    margin-top: auto;
    margin-bottom: auto;
    transform: unset !important;
    position: unset !important;
}

.get-in-touch {
    color: black;
    text-decoration: underline;
}

// Fixing the alert on the product image gallery
.mpageverify-message.mpageverify-img-notice {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
}

.mpageverify-message.mpageverify-img-notice img {
    max-width: 70px;
    max-height: 70px;
    object-fit: contain;
}




//Product page rework
.catalog-product-view .product.media {
    min-width: 487px !important;
    max-width: 487px !important;
	margin: 0 0 90px;
}

.catalog-product-view .product-info-main {
    flex: 0 0 57%;
    max-width: 57%;
    margin: 0 0 90px;
}

.catalog-product-view .product-info-main .page-title-wrapper .page-title {
    font-size: 36px;
    line-height: 46px;
    font-weight: 900;
    color: #3d85ce !important;
    margin: -18px 0 11px !important;
}

.catalog-product-view .special-price .price-label {
    display: none;
}

.catalog-product-view .product-info-main .price-box {
    margin: 0 0 35px;
    display: flex;
    justify-content: end;
    align-items: center;
    justify-content: start;
}

.catalog-product-view  .product-info-main span.old-price {
    margin: 0 15px 0 0;
    order: -1;
    font-size: 14px;
    font-weight: 700;
}

.catalog-product-view  .product-info-main span.old-price .price {
    color: #FE4546;
}

.catalog-product-view  .product-info-main .price-box .price {
    font-size: 32px;
    color: #212529;
    line-height: 32px;
}

.catalog-product-view .special-price .percent-saving {
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
    color: #83888d;
}

.catalog-product-view .product-info-main .product-info-price {
    border: none;
    padding: 0;
    margin: 0;
}

.catalog-product-view .media .fotorama__stage {
    margin: 0;
}

.catalog-product-view .fotorama__nav-wrap--vertical.fotorama__nav-wrap {
    position: relative;
    display: block !important;
    top: auto;
    left: auto;
    float: left;
    width: 100%;
    margin: 20px 0 0;
}

.catalog-product-view .fotorama__nav {
    width: 100% !important;
    margin: 0;
    float: left;
    height: auto !important;
}

.catalog-product-view .fotorama__thumb-border {
    max-width: 80px;
    float: left;
}

.catalog-product-view .fotorama__nav-wrap--vertical .fotorama__nav__shaft {
    float: left;
}

.catalog-product-view .media .fotorama__nav-wrap--vertical.fotorama__nav-wrap .fotorama__nav__frame--thumb {
    float: left;
    margin: 0 11px 11px 0;
    height: 80px;
    max-width: 80px;
    opacity: 0.7;
}

.catalog-product-view .product-info-main .product-add-form #qty {
    width: 50px;
    height: 50px;
    text-align: center;
    padding: 0;
}

/* Chrome, Safari, Edge, Opera */
.catalog-product-view input[type="number"], .catalog-product-view .product-info-main .product-add-form input::-webkit-outer-spin-button, .catalog-product-view input[type="number"], .catalog-product-view .product-info-main .product-add-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.catalog-product-view input[type="number"], .catalog-product-view .product-info-main .product-add-form input[type=number] {
  -moz-appearance: textfield;
}

.catalog-product-view .product-info-main .box-tocart .action.tocart, .product-options-bottom .box-tocart .action.tocart {
    padding: 0 35px;
    height: 50px;
    display: flex;
    align-items: center;
}

.catalog-product-view  .product-info-main .product-add-form #product-addtocart-button span {
    font-size: 15px;
    line-height: 32px;
    color: #303030;
}

.catalog-product-view .product-info-main .box-tocart .action.tocart:hover, .product-options-bottom .box-tocart .action.tocart:hover {
    background: #3d85ce;
}

.catalog-product-view .product-info-main .product-add-form #product-addtocart-button:hover span {
    color: white;
}

.catalog-product-view .product-options-bottom .field.qty .label {
    font-size: 14px;
}

.catalog-product-view .fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
    position: relative;
}

.catalog-product-view .media .fotorama__nav-wrap--vertical.fotorama__nav-wrap .fotorama__nav__frame--thumb.fotorama__active {
    opacity: 1;
    border: 1px solid #e2e2e2;
}

.catalog-product-view .media .fotorama__nav-wrap--vertical.fotorama__nav-wrap .fotorama__nav__frame--thumb:nth-child(6), .catalog-product-view .media .fotorama__nav-wrap--vertical.fotorama__nav-wrap .fotorama__nav__frame--thumb:nth-child(11), .catalog-product-view .media .fotorama__nav-wrap--vertical.fotorama__nav-wrap .fotorama__nav__frame--thumb:nth-child(17) {
    margin: 0;
}

.catalog-product-view .product-info-main .product.attribute.overview {
    border: none;
    padding: 0;
    font-size: 16px;
    margin: 0 0 30px;
    float: left;
    width: 100%;
}

.catalog-product-view .product-info-main .product.attribute.overview p {
    font-weight: 300;
}

.catalog-product-view .product-info-main .product.attribute.overview .value > *:last-child {
    margin-bottom: -9px;
}
.catalog-product-view .product-info-main .product.attribute.overview .value > *:first-child {
    margin-top: -7px;
}

.catalog-product-view .product-info-main .product-add-form, .catalog-product-view .catalog-product-view .product-info-main .product-info-price, .catalog-product-view .product-info-main .product-add-form form, .catalog-product-view .product-info-main .product-add-form p {
    float: left;
    width: 100%;
}

.catalog-product-view .product-options-wrapper {
    padding: 0;
}

.catalog-product-view .product-info-main .product-add-form {
    padding: 0 0 30px;
    margin: 0 0 30px;
}

.catalog-product-view .product-info-main .box-tocart, .catalog-product-view .product-options-bottom .box-tocart {
    margin: 0;
}

.catalog-product-view .product-info-main .product-options-bottom:after, .catalog-product-view .bundle-actions:after {
    content: "";
    background: url(/media/wysiwyg/payment_and_couriers.jpg);
    width: 230px;
    height: 50px;
    position: absolute;
    background-size: 500px 30px;
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: left;
}

.catalog-product-view .product-info-main .bundle-actions {
    position: relative;
    float: left;
    width: 100%;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 30px;
    margin-bottom: 22px;
}

.catalog-product-view .product-options-bottom {
    float: left;
    width: 100%;
    position: relative;
}

.catalog-product-view  .product-payment-methods-and-couriers {
    float: left;
    width: 100%;
    position: relative;
}

.catalog-product-view  .block-static-block.widget .product-payment-methods-and-couriers p {display: flex;justify-content: space-between;align-items: center;}

.catalog-product-view .product-info-main .block-static-block.widget .product-payment-methods-and-couriers img {
    height: 50px;
    object-fit: cover;
    object-position: right;
    width: 300px;
}

.catalog-product-view .product.info.detailed .data.item.title.active {
    border-color: #3d85ce;
    border-bottom: 1px solid white;
}

.catalog-product-view .product.info.detailed .data.item.title.active a {
    color: #3d85ce !important;
}

.catalog-product-view .product.info.detailed .data.item.title {
    width: auto;
}

.catalog-product-view .product.info.detailed .data.item.title a {
    padding: 0 30px;
}

.catalog-product-view .product.info.detailed .data.item.content {
    padding: 50px 0 0;
}

.catalog-product-view strong#block-related-heading {
    font-size: 0;
    font-weight: 900;
    color: #3d85ce;
    float: left;
    width: 100%;
    text-align: center;
    margin: -4px 0 50px;
    font-size: 0;
    font-family: 'futura-pt', sans-serif;
}

#block-related-heading:before {
    content: "Other products you may be interested in";
    font-size: 34px;
}

.catalog-product-view .related {
    margin: 0 0 90px;
}

.catalog-product-view .product.info.detailed {
    margin: 0 0 75px;
    float: left;
    width: 100%;
}

.catalog-product-view .product.data.items {
    margin: 0;
}

.catalog-product-view .related .flickity-viewport .product-item {
    width: 25% !important;
}

.catalog-product-view .product-image-photo {
    position: relative;
    right: auto;
    top: auto;
    float: left;
    bottom: auto;
}

.catalog-product-view .product-image-wrapper {
    float: left;
    width: 100%;
    height: 245px;
    padding: 30px !important;
    border: 1px solid #e8e8e8;
}

.catalog-product-view .related .product-item-photo, .related .product-image-container {
    float: left;
    width: 100% !important;
}

.catalog-product-view .related .product-item-info {
    padding: 0 25px;
    display: flex;
    flex-wrap: wrap;
}

.catalog-product-view .flickity-prev-next-button svg {
    display: none;
}

.catalog-product-view .flickity-prev-next-button:before {
    content: '\f2ff';
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    opacity: 1;
    font-size: 26px;
    color: #303030;
}

.catalog-product-view .flickity-prev-next-button.next:before {
    content: '\f301';
}

.catalog-product-view .flickity-prev-next-button {bottom: 0;top: auto;opacity: 1 !important;}

.catalog-product-view .flickity-prev-next-button:disabled {
    opacity: 0.55;
}

.related .product-item .price-box {
    margin: 0 0 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.related .special-price {
    margin: 0 0 0 0 !important;
}

.related .old-price {
    order: -1;
}

.related .price-container .price {
    font-size: 20px;
    font-weight: 700;
    color: #212529;
}

.related .old-price .price {
    font-size: 14px;
    font-weight: 700;
    color: #fe4545;
}

.catalog-product-view .related .product-item-photo {
    margin: 0 0 30px;
}

.related .product-item-name {
    margin-bottom: 7px;
}

.speak-to-expert {
    float: left;
    width: 100%;
    background: #f7f7f7;
    padding: 33px 45px;
    position: relative;
    margin-top: 50px;
    padding-left: 220px;
}

.speak-to-expert .subtitle {
    float: left;
    width: 100%;
    margin: -10px 0 -2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    color: #64686d;
}

.speak-to-expert .title {
    float: left;
    width: 100%;
    font-size: 28px;
    font-weight: 700;
    color: #303030;
    font-family: 'futura-pt', sans-serif;
    margin: 0 0 10px;
    letter-spacing: -0.02em;
}

.speak-to-expert .title strong {
    color: #3d85ce;
}

.speak-to-expert .meta {
    float: left;
    width: 100%;
    position: relative;
    padding-left: 45px;
    font-size: 16px;
    font-weight: 400;
    color: #212529;
    min-height: 30px;
    margin: 0 0 8px;
}

.speak-to-expert img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 180px;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
}

.speak-to-expert .meta:before {
    content: "";
    font-size: 14px;
    width: 30px;
    height: 30px;
    background: #f8c602;
    position: absolute;
    left: 0;
    border-radius: 100px;
    top: -2px;
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    display: flex;
    justify-content: center;
    align-items: center;
}

.speak-to-expert .meta.email {
    margin: 0;
}

.speak-to-expert .meta.call:before {
    content: '\f2be';
}

.speak-to-expert .meta.email:before {
    content: '\f15a';
}

.catalog-product-view .product-options-wrapper .field {
    float: left;
    width: 100%;
    position: relative;
}

.catalog-product-view .product-options-wrapper .field label span {
    font-size: 14px;
    font-weight: 700;
    color: #212529;
    margin: 0;
}

.catalog-product-view .product-options-wrapper select {
    float: right;
    height: 50px;
    border-radius: 0;
    border: 1px solid #e2e2e2;
    padding: 0 10px;
    margin: 0 0 0 22px;
    font-size: 16px;
    font-weight: 300;
    color: #212529;
    width: 100%;
}

.catalog-product-view iframe[title="vimeo-player"] {
    min-height: 500px;
}



.catalog-product-view .product-options-wrapper {
    float: left;
    width: 100%;
    margin: 0 0 25px;
}

.catalog-product-view  .product-info-main .stock.available {
    font-size: 14px;
    font-weight: 700;
    color: #22b366;
    padding: 2px 10px 2px;
    background: #e2f8ec;
}

.catalog-product-view  .action.primary.customize {
    height: 50px;
    padding: 0;
    background: white;
    border: 1px solid;
}

.catalog-product-view  .action.primary.customize span {
    font-size: 15px;
    font-weight: 600;
    text-transform: none;
    padding: 0 30px;
}

.catalog-product-view .product-info-stock-sku {
    float: left;
    width: auto;
    margin: 0;
    padding: 5px 0;
}

.catalog-product-view .fieldset-bundle-options {
    float: left;
    width: 100%;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .legend.title {
    font-size: 30px;
    line-height: 40px;
    color: #3d85ce;
    font-weight: 700;
    text-transform: none;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .action.back.customization {
    display: none;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field.option {
    float: left;
    width: 100%;
    margin: 0 0 35px !important;
    box-shadow: none;
    padding: 0;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field.option label {
    position: relative;
    margin: 0 0 10px !important;
    min-width: auto;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field.option label:after {
    font-size: 12px;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field.option select {
    width: calc(100% - 80px);
    float: left;
    margin: 0;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field.option .control {
    float: left;
    width: 100%;
    display: flex;
    align-items: end;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field .qty-holder {
    background: none;
    padding: 0;
    margin: 0;
    float: right;
    max-width: 80px;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field .qty-holder input {
    max-width: 50px;
    padding: 0 !important;
    background: white;
    height: 50px;
    border: 1px solid #e2e2e2;
}

.catalog-product-view #bundleSummary .title strong {
    font-size: 14px;
    font-weight: 600;
    color: #303030;
    text-transform: uppercase;
    letter-spacing: 0.220em;
    margin: 0 0 0;
}

.catalog-product-view #bundleSummary {
    padding: 35px;
    background: #f7f7f7;
    border: none;
}

.catalog-product-view .bundle-options-wrapper {
    padding: 0;
    border: none;
}

.catalog-product-view .bundle-info .product-image-container, .catalog-product-view .bundle-info .name, .bundle-info .stock {
    display: none;
}

.catalog-product-view div#bundleSummary .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
}

.catalog-product-view #bundleSummary .bundle-summary {
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0 0 30px;
}

.catalog-product-view #bundleSummary .bundle-summary .subtitle {
    display: none;
}

.catalog-product-view .bundle-info .product-details {
    float: left;
    width: 100%;
}

.catalog-product-view .bundle-info .box-tocart .fieldset {
    display: flex;
    float: left;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.catalog-product-view .bundle-info .box-tocart .qty input {
    max-width: 50px;
    height: 50px;
    padding: 0 !important;
}

.catalog-product-view #bundleSummary .bundle-info .product-details .action.primary.tocart {
    padding: 0 30px;
    height: 50px;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field .qty-holder label {
    display: none;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field .qty-holder:before {
    content: "Qty";
    font-size: 14px;
    font-weight: 700;
    color: #212529;
    margin: 0;
    float: left;
    position: relative;
    left: 20px;
    top: -18px;
}

.catalog-product-view span.price-notice {
    font-weight: 700;
}

.catalog-product-view ul.bundle.items ul.bundle.items li {
    float: left;
    width: 100%;
}

.catalog-product-view ul.bundle.items .label, .bundle-options-container p.required {
    display: none;
}

.catalog-product-view ul.bundle.items li {
    border-top: 1px solid #c5c5c5;
    padding: 10px 0 0;
}

.catalog-product-view #bundleSummary .title {
    border: none;
    padding: 0;
    margin: 0 0 20px;
}

.catalog-product-view p.price-as-configured {
    float: left;
    width: 100%;
    margin: 0 0 0;
}

.catalog-product-view .bundle-options-container .price-box .price {
    font-size: 32px;
    font-weight: 700;
    color: #212529;
    float: right;
}

.catalog-product-view .bundle-options-container .price-box .price:before {
    content: "Total cost :";
    font-size: 14px;
    font-weight: 500;
    color: #83888d;
    margin-right: 3px;
}

.catalog-product-view #bundleSummary .bundle-info .qty .control input[type="number"] {
    border: 1px solid #e2e2e2;
}

.catalog-product-view .action.primary.customize:hover {
    background: #303030;
}

.catalog-product-view .action.primary.customize:hover span {
    color: white;
}

.product-info-main .product-add-form .swatch-attribute-options {
    float: left;
    width: 100%;
    padding: 0;
    border: none;
}

.product-options-wrapper .swatch-attribute .swatch-attribute-label {
    font-size: 14px;
    font-weight: 700;
    color: #212529;
    margin: 0;
    min-width: 130px;
    margin: 0 !important;
    position: absolute;
    left: 0;
    top: 23px;
	z-index: -1;
}

.product-options-wrapper .swatch-attribute {
    float: left;
    width: 100%;
    position: relative;
}

.product-info-main .product-add-form .swatch-attribute-options .color {
    height: 50px;
    width: 50px;
}

.product-info-main .product-add-form .swatch-attribute-options {
    width: calc(100% - 130px);
    float: right;
}

.product-info-main .product-add-form .swatch-attribute-options select {
    width: 100%;
}

.check-size-guide {
    font-size: 16px;
    color: #3d85ce;
    font-weight: 600;
    float: left;
    margin: 0 0 24px;
    box-shadow: inset 0 -0.12rem 0 0 #3d85ce;
    text-decoration: none !important;
}

.check-size-guide:hover {
    color: #212529;
    box-shadow: inset 0 -0.12rem 0 0 #212529;
}

.product-options-wrapper .swatch-opt {
    float: left;
    width: 100%;
    margin: 0 0 10px;
}

.availability.only {
    display: none;
}

.swatch-option.selected:after {
    content: '\f26b';
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    color: white;
    font-size: 18px;
    float: left;
    width: 44px;
    padding: 14px 0;
}

.swatch-option.selected {
    outline: none !important;
}

.speak-to-expert .meta a {
    color: #212529;
    font-weight: 600;
    box-shadow: inset 0 -0.12rem 0 0 #212529;
    text-decoration: none !important;
}

.speak-to-expert .meta a:hover {
    color: #3d85ce;
    box-shadow: inset 0 -0.12rem 0 0 #3d85ce;
}

.catalog-product-view .product-info-main .product-add-form form {
    display: flex;
    flex-wrap: wrap;
}

.catalog-product-view .product-options-wrapper {
    order: 1;
}

.catalog-product-view .product-options-bottom {
    order: 2;
}

.messages .message {
	box-shadow: none !important;
}

.catalog-product-view .gallery-placeholder {
    position: sticky;
    top: 20px;
}

.options-list, .options-list .label {
    float: left;
    width: 100%;
}

.options-list label:before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 11px 0 0;
}

.options-list input:checked ~ label:before {
    content: "x";
    font-family: sans-serif;
    font-weight: 400;
}

.options-list input {
    display: none;
}

.options-list label {
    display: flex;
    align-items: center;
    margin: 0;
}

.options-list .label span {
    font-size: 16px;
    line-height: 18px;
}

.options-list .label span.price-notice {
    padding-left: 4px;
}




















//################################################
//################## RESPONSIVE ##################
//################################################

/*Phones */
@media only screen
and (max-width: 575px) {

    .page-main .category-view {
        margin: 0 !important;
    }

    .perk-band ul {
        overflow-x: scroll;
    }

    .catalog-products .product-item {
        width: 50% !important;
    }

    .mobile-show {
        display: block !important;
    }

    .mobile-hide {
        display: none !important;
    }

    .category-descriptions p {
        float: left;
        width: 100%;
        height: 65px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: none;
    }

    .category-descriptions p:first-of-type {
        display: block;
    }

    a.btn {
        float: left;
        width: 100%;
        text-align: center;
        background: #f8c602;
        padding: 10px 15px;
        font-size: 17px;
        color: #303030;
    }

    .category-descriptions.active p {
        height: auto;
        display: block;
    }

    .page-title-wrapper.product .page-title {
        font-size: 23px;
    }

    .product-options-wrapper select {
        float: left;
        width: 100%;
        margin: 0 0 20px;
    }

    .btn i {
        margin: 0 10px 0 0;
    }

    .catalog-category-view .special-price .percent-saving {
        font-size: 12px;
        padding: 2px 0;
    }

    .catalog-category-view .special-price .price-wrapper {
        padding: 0;
        background: none;
        color: #f12670;
        float: left;
        width: 100%;
    }

    .product-item .price-box .price-label {
        float: left;
        font-size: 13px;
        font-weight: 700;
        margin: 0 3px 0 0;
    }

    span.price-container {
        text-align: center;
        float: left;
        width: 100%;
    }

    .product.details.product-item-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-between;
        height: calc(100% - 210px);
    }

    .page-main .catalog-products .product-items {
        display: flex;
        flex-wrap: wrap;
    }

    .catalog-products .product-item .product-item-info {
        height: 100%;
    }

    .product-item .special-price .price, .product-item .minimal-price .price {
        font-size: 13px;
    }

    .product-item .price-box {
        float: left;
        width: 100%;
    }

    .product.name a {
        font-size: 14px;
    }

    .normal-price span.price-label {
        line-height: 23px;
        font-size: 14px;
    }

    span.price-label {
        display: inline !important;
    }

    span.old-price .price {
        text-decoration: line-through !important;
    }

    .catalog-product-view span.price-container {
        width: auto;
    }

    .catalog-product-view span.price-container.price-final_price {font-size: 18px;line-height: 1.2;}

    .product-info-main .price-box .price-wrapper .price {
        font-size: 22px;
        margin: 0 5px 0 0;
    }

    .mb-10 {
        margin-bottom: 10px !important;
    }

    .mb-20 {
        margin-bottom: 20px !important;
    }

    .mb-30 {
        margin-bottom: 30px !important;
    }

    .mt-10 {
        margin-top: 10px !important;
    }

    .mt-20 {
        margin-top: 20px !important;
    }

    .mt-30 {
        margin-top: 30px !important;
    }

    .no-margin {
        margin: 0 !important;
    }

    .page-main .catalog-products .product-items .product-item .product-item-info {
        height: auto;
        margin-bottom: 20px !important;
        float: left;
    }

    .giftcard-template-container-preview, div#giftcard-template-container, div#giftcard-template-container > div, .giftcard-template-preview, .preview-giftcard, #preview-giftcard-image, #preview-giftcard-message {
        width: 100% !important;
    }

    .giftcard-template-container-preview, .giftcard-template-setting-select {
        display: none;
    }

    .product-info-main .product-info-price {
        border-bottom: none;
    }

    ul.giftcard-amount {
        flex-wrap: wrap;
    }

    .widget.block.block-static-block img {
        max-width: 100%;
    }

    ul.giftcard-amount li {
        margin-bottom: 10px;
    }

	.whats-up-in-the-hood .archive-link {
		display: none;
	}

	.featured-article {
		padding: 0;
		margin-bottom: 70px;
	}

	.catalog-product-view .product.media {
		min-width: auto !important;
		max-width: 100% !important;
	}

	.catalog-product-view .breadcrumbs {
//		padding: 30px 0 !important;
		display: none !important;
	}

	.catalog-product-view .product.media {
		margin: 20px 0 50px;
	}

	.catalog-product-view .product-info-main .product-options-bottom:after, .catalog-product-view .bundle-actions:after {
		right: auto;
		left: 0;
		top: auto;
		bottom: 0;
	}

	.catalog-product-view .product-info-main .product-options-bottom {
		padding-bottom: 55px;
	}

	.catalog-product-view .product-payment-methods-and-couriers p:before {margin: 0 0 20px;}

	.catalog-product-view .block-static-block.widget .product-payment-methods-and-couriers p {
		flex-wrap: wrap;
	}

	.speak-to-expert {
		padding: 30px;
//		padding-bottom: 190px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.speak-to-expert img {
        left: auto;
        position: relative;
        margin: 0 0 30px;
        width: 100%;
        max-height: 200px;
    }

	.catalog-product-view .product.info.detailed .data.item.content {
		margin: 0;
		border-color: #3d85ce;
		padding: 30px 20px 20px;
	}

	.catalog-product-view .product.info.detailed .data.item.title {
		margin: 0;
	}

	.product-info-main .special-price .price-container, .catalog-product-view span.price-container {
		width: auto;
	}

	.catalog-product-view .product.info.detailed .data.item.title {
		float: left;
		width: 100%;
		z-index: 1;
	}

	.catalog-product-view .product.info.detailed .data.item.title.active {
		border: 1px solid #3d85ce;
	}

	.catalog-product-view .product.info.detailed .data.item.content {
		float: left;
		width: 100%;
		border-top: none;
		border-bottom: none;
	}

	.catalog-product-view .product.info.detailed .data.item.title {
		float: left;
		width: 100%;
		border: 1px solid #7d7d7d;
		border-bottom: none;
	}

	.catalog-product-view .product.info.detailed .data.item.content:last-of-type {
		border-bottom: 1px solid #3d85ce;
	}

	.catalog-product-view .product.info.detailed .data.item.title:nth-last-child(2) {
		border-bottom: 1px solid;
	}

	.catalog-product-view .product.info.detailed .data.item.title.active:nth-last-child(2) {
		border-color: #3d85ce;
	}

	.product-options-wrapper .swatch-attribute .swatch-attribute-label {
		position: relative;
		top: auto;
		float: left;
	}

	.product-info-main .product-add-form .swatch-attribute-options {
		width: 100%;
		overflow: hidden;
	}

	.product-options-wrapper .swatch-attribute {
		margin: 0 0 20px;
	}

	.catalog-product-view .bundle-options-wrapper, .catalog-product-view #bundleSummary {
		width: 100%;
	}

	.catalog-product-view #bundleSummary {
		margin-bottom: 40px;
	}

	fieldset.fieldset.fieldset-bundle-options span.price-container {
		float: none;
	}

	.catalog-product-view .product-info-main .bundle-actions {
		padding-bottom: 90px;
	}

	.catalog-product-view .bundle-actions:after {
		bottom: 23px;
	}

	.catalog-product-view .product-info-main {
		margin: 0 0 50px;
	}

	.product.attribute.description img, .product.attribute.description iframe {
		min-height: auto;
	}
	
    .product.attribute.description img {
		height: auto;
		margin: 0px;
	}


	.catalog-product-view .related .flickity-viewport .product-item {
		width: 100% !important;
	}

	.catalog-product-view .product.media {
		margin: 0 0 50px;
	}

	.catalog-product-view .fieldset-bundle-options {
		background: white;
	}

	.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field.option {
		margin: 0 0 15px !important;
	}

	.catalog-product-view #bundleSummary {
		float: left;
	}

	.catalog-product-view .related {
		margin: 0 0 30px;
	}

	.bundle-options-container .block-bundle-summary .box-tocart .actions, .bundle-options-container .block-bundle-summary .box-tocart .field.qty {
		margin: 0;
	}

	.catalog-product-view p.price-as-configured {
		margin: 30px 0 0;
	}

	.column.main .aside-related .product-items {
		margin: 0;
	}

	.products-related .product-item .price-box .price-label {
		display: none !important;
	}

	.catalog-product-view .products-related span.price-container.price-final_price .price {
		font-size: 20px;
	}

	.related .product-item .price-box {
		flex-wrap: wrap;
	}

	.related .old-price {
		float: left;
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 0 0 6px;
	}

	.products-related .special-price .percent-saving {
		line-height: 20px;
		margin-left: 7px;
	}

	.products-related .product-item .price-box .old-price .price-label {
		display: block !important;
	}

	.catalog-product-view #bundleSummary .bundle-info .product-details .action.primary.tocart {
		padding: 0 20px;
	}

	.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field.option .control div.mage-error {position: absolute;bottom: -20px;font-size: 12px;}

	.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field.option select {
		overflow: hidden;
	}

	.catalog-product-view .product-info-main .page-title-wrapper .page-title {
		font-size: 25px;
		line-height: 32px;
	}

	.catalog-product-view .product-info-main .product-info-price span.price-label {
		display: none !important;
	}

	.catalog-product-view .media .fotorama__stage {
		max-height: 280px;
	}

	.catalog-product-view .product.media {
		margin: 0 0 15px;
	}

}

/* Phones Landscape */
@media only screen
and (min-width : 576px)
and (max-width : 767px) {

.catalog-product-view .container {
    max-width: 600px;
}

.catalog-product-view .breadcrumbs {
    padding: 30px 0 !important;
}

.catalog-product-view .product.media {
    min-width: auto !important;
    max-width: 100% !important;
    width: 100%;
}

.catalog-product-view .product.info.detailed .data.item.content {
    margin: 0;
    float: left;
    width: 100%;
    padding: 30px;
}

.catalog-product-view .product.info.detailed .data.item.title {
    margin: 0;
    float: left;
    width: 100%;
}

.catalog-product-view .product.info.detailed .data.item.title {
	float: left;
	width: 100%;
	z-index: 1;
}

.catalog-product-view .product.info.detailed .data.item.title.active {
	border: 1px solid #3d85ce;
}

.catalog-product-view .product.info.detailed .data.item.content {
	float: left;
	width: 100%;
	border-top: none;
	border-bottom: none;
}

.catalog-product-view .product.info.detailed .data.item.title {
	float: left;
	width: 100%;
	border: 1px solid #7d7d7d;
	border-bottom: none;
}

.catalog-product-view .product.info.detailed .data.item.content:last-of-type {
	border-bottom: 1px solid #3d85ce;
}

.catalog-product-view .product.info.detailed .data.item.title:nth-last-child(2) {
	border-bottom: 1px solid;
}

.catalog-product-view .product.info.detailed .data.item.title.active:nth-last-child(2) {
	border-color: #3d85ce;
}

.product.attribute.description img, .product.attribute.description iframe {
    min-height: auto;
    margin: 10px 0 30px;
}

.catalog-product-view .related .flickity-viewport .product-item {
    width: 50% !important;
}

.catalog-product-view .fieldset-bundle-options {
    background: white;
}

.catalog-product-view .bundle-options-wrapper .fieldset-bundle-options .field.option {
    margin: 0 0 10px !important;
}

.catalog-product-view #bundleSummary {
    float: left;
    width: 100%;
}

}

/* Plus phones */
@media only screen
and (min-device-width: 414px)
and (max-device-width: 736px)
and (-webkit-min-device-pixel-ratio: 3) {

    .product.details.product-item-details {
        height: calc(100% - 234px);
    }

}

/*Ipad mins*/
@media only screen
and (min-width : 768px)
and (max-width : 991px) {

    .perk-band ul {
        overflow-x: scroll;
    }

    .catalog-products .product-item {
        width: 50% !important;
    }

    .page-main .block.filter {
        display: none;
    }

    .mobile-show {
        display: block !important;
    }

    .mobile-hide, .hidden-md {
        display: none !important;
    }

    .category-descriptions p {
        float: left;
        width: 100%;
        height: 65px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: none;
    }

    .category-descriptions p:first-of-type {
        display: block;
    }

    a.btn {
        float: left;
        width: 100%;
        text-align: center;
        background: #f8c602;
        padding: 10px 15px;
        font-size: 17px;
        color: #303030;
    }

    .category-descriptions.active p {
        height: auto;
        display: block;
    }

    .page-title-wrapper.product .page-title {
        font-size: 23px;
    }

    .product-options-wrapper select {
        float: left;
        width: 100%;
        margin: 0 0 20px;
    }

    .btn i {
        margin: 0 10px 0 0;
    }

    .catalog-category-view .special-price .percent-saving {
        display: none;
    }

    .catalog-category-view .special-price .price-wrapper {
        padding: 0;
        background: none;
        color: #f12670;
        float: left;
    }

    .product-item .price-box .price-label {
        float: left;
        font-size: 13px;
        font-weight: 700;
        margin: 0 3px 0 0;
    }

    span.price-container {
        text-align: center;
        float: left;
        width: 100%;
    }

    .product.details.product-item-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-between;
        height: calc(100% - 307px);
    }

    .page-main .catalog-products .product-items {
        display: flex;
        flex-wrap: wrap;
    }

    .catalog-products .product-item .product-item-info {
        height: 100%;
    }

    .product-item .special-price .price, .product-item .minimal-price .price {
        font-size: 13px;
    }

    .product-item .price-box {
        float: left;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .product.name a {
        font-size: 14px;
    }

    .normal-price span.price-label {
        line-height: 23px;
        font-size: 14px;
    }

    .page-products .products-grid .product-item {
        width: 33.3333% !important;
    }

    .mb-10 {
        margin-bottom: 10px !important;
    }

    .mb-20 {
        margin-bottom: 20px !important;
    }

    .mb-30 {
        margin-bottom: 30px !important;
    }

    .mt-10 {
        margin-top: 10px !important;
    }

    .mt-20 {
        margin-top: 20px !important;
    }

    .mt-30 {
        margin-top: 30px !important;
    }

    .no-margin {
        margin: 0 !important;
    }

    .newsletter--footer form {
        display: flex !important;
    }

    span.price-label {
        display: none !important;
    }

    span.old-price .price {
        text-decoration: line-through !important;
    }

    .page-main .block.filter {
        display: block;
    }

    .page-main .category-view {
        margin: 0;
    }

	.other-articles {
		padding-left: 125px;
	}

	.catalog-product-view .product.media {
		min-width: auto !important;
		max-width: none !important;
		width: 100%;
	}

	.page-layout-1column .product-info-main {flex: 0 0 100% !important;max-width: 100% !important;}

	.catalog-product-view .breadcrumbs {
		padding: 25px 0 !important;
	}

	.catalog-product-view .product.media {
		margin: 0 0 25px;
	}

	.product-info-main .special-price .price-container, .catalog-product-view span.price-container {
		width: auto;
	}

	.catalog-product-view .special-price .percent-saving {
		margin-left: 13px;
	}

	.catalog-product-view .bundle-options-wrapper, .catalog-product-view #bundleSummary {
		width: 100%;
	}

	.catalog-product-view #bundleSummary {
		margin-bottom: 40px;
	}

	fieldset.fieldset.fieldset-bundle-options span.price-container {
		float: none;
	}

	.catalog-product-view .related .flickity-viewport .product-item {
		width: 33.3333% !important;
	}

}

/*Ipads*/
@media only screen
and (min-width : 992px)
and (max-width : 1200px) {

    .perk-band ul {
        overflow-x: scroll;
    }

    .catalog-products .product-item {
        width: 50% !important;
    }

    .page-main .block.filter {
        display: none;
    }

    .mobile-show {
        display: block !important;
    }

    .mobile-hide {
        display: none !important;
    }

    .category-descriptions p {
        float: left;
        width: 100%;
        height: 65px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: none;
    }

    .category-descriptions p:first-of-type {
        display: block;
    }

    a.btn {
        float: left;
        width: 100%;
        text-align: center;
        background: #f8c602;
        padding: 10px 15px;
        font-size: 17px;
        color: #303030;
    }

    .category-descriptions.active p {
        height: auto;
        display: block;
    }

    .page-title-wrapper.product .page-title {
        font-size: 23px;
    }

    .product-options-wrapper select {
        float: left;
        width: 100%;
        margin: 0 0 20px;
    }

    .btn i {
        margin: 0 10px 0 0;
    }

    .catalog-category-view .special-price .percent-saving {
        display: none;
    }

    .catalog-category-view .special-price .price-wrapper {
        padding: 0;
        background: none;
        color: #f12670;
        float: left;
    }

    .product-item .price-box .price-label {
        float: left;
        font-size: 13px;
        font-weight: 700;
        margin: 0 3px 0 0;
    }

    span.price-container {
        text-align: center;
        float: left;
        width: 100%;
    }

    .product.details.product-item-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-between;
        height: calc(100% - 307px);
    }

    .page-main .catalog-products .product-items {
        display: flex;
        flex-wrap: wrap;
    }

    .catalog-products .product-item .product-item-info {
        height: 100%;
    }

    .product-item .special-price .price, .product-item .minimal-price .price {
        font-size: 13px;
    }

    .product-item .price-box {
        float: left;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product.name a {
        font-size: 14px;
    }

    .normal-price span.price-label {
        line-height: 23px;
        font-size: 14px;
    }

    span.price-label {
        display: none !important;
    }

    span.old-price .price {
        text-decoration: line-through !important;
    }

    .page-products .products-grid .product-item {
        width: 33.3333% !important;
    }

    .mb-10 {
        margin-bottom: 10px !important;
    }

    .mb-20 {
        margin-bottom: 20px !important;
    }

    .mb-30 {
        margin-bottom: 30px !important;
    }

    .mt-10 {
        margin-top: 10px !important;
    }

    .mt-20 {
        margin-top: 20px !important;
    }

    .mt-30 {
        margin-top: 30px !important;
    }

    .no-margin {
        margin: 0 !important;
    }

	.catalog-product-view .product.media {
		min-width: auto !important;
		max-width: none !important;
		width: 38%;
	}

	.page-layout-1column .product-info-main {flex: 0 0 62%;max-width: 62%;}

	.catalog-product-view .gallery-placeholder {
		position: sticky;
		top: 30px;
	}

	.product-info-main .special-price .price-container, .catalog-product-view span.price-container {
		width: auto;
	}

	.catalog-product-view .special-price .percent-saving {
		margin-left: 13px;
	}

}
