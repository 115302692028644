.product-info-main {

  font-size: 18px;
  font-family: poppins;

  .product.attribute.overview {
    border: 1px solid #e2e2e2;
    padding: 20px;
    font-size: 16px;
    line-height: 28px;
  }

  .page-title-wrapper {
    text-align: left !important;
    margin-bottom: 30px;
    .page-title {
      color: #EF0057 !important;
      margin-bottom: 0 !important;

    }
  }

  .action.mailto.friend {
    display: none;
  }

  .product-info-price {
    border-bottom: 1px solid #8888884d;
    padding-bottom: 20px;


    .price {
      font-family: futura-pt;
    }

  }

  .product-add-form {
    border-bottom: 1px solid #8888884d;
    padding-bottom: 20px;

    .swatch-attribute.color {
      span {
        bottom: 25px;
      }
      .swatch-attribute-selected-option {
        padding-right: 17px;
        position: relative;
      }
    }

    .swatch-attribute-options {
      display: inline-block;
      border: 1px solid #E2E2E2;
      padding: 15px;

      .color {
        width: 32px;
        height: 30px;
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    #product-addtocart-button {

      span {
        font-size: 22px;
        font-weight: bold;
        font-family: futura-pt;
      }

      &:after {
        background: #BBC9ED;
      }
    }

    #qty {
      border: 1px solid #E2E2E2;
      width: 100px;
    }

  }

  svg {
    path {
      opacity: 0.4;
      fill: #EF0057;
    }
  }

  .reviews-actions {
    display: none !important;
  }
  .product.attribute.sku {
    display: none !important;
  }


}

.product.info.detailed {

  .data.item.title {
    width: 188px;
    height: 66px;

    position: relative;
    z-index: -1;
    margin: 0 10px 0 10px;
    text-align: center;

    &.active {
      z-index: 2;
      border: 1px solid #e2e2e2;
      border-bottom: 1px solid white;

      a {
        color: #EF0057 !important;
      }
    }

    a {
      border: none;
      font-size: 18px !important;
      line-height: 58px !important;
      font-family: futura-pt;
      background: none !important;
    }
  }

  .data.item.content {
    margin-top: 65px;
    padding: 50px;
    position: relative;
    font-size: 14px;
    line-height: 28px;

    table {
        width: 100%;
        td {
            padding: 12px 20px;
            p {
                margin: 0px;
            }
        }
    }

    img {
      max-width: 100%;
    }
  }

}

#reviews {
  input {
    height: 52px;
    border: 1px solid #E2E2E2;
  }
  #review_field {
    min-width: 100%;
    border: 1px solid #E2E2E2;
    min-height: 180px;
  }
  .action.submit.primary {
    background: none;
    border: none;
    z-index: 1;
  }
  .review-legend {
    font-size: 18px !important;
  }

}

.media {

  .fotorama__nav-wrap--vertical.fotorama__nav-wrap {

    .fotorama__thumb-border {
      display: none;
    }

    .fotorama__nav__frame--thumb {
      border: 1px solid #e2e2e2;
      margin-bottom: 30px;
      padding: 0;
      width: auto !important;

      .fotorama__thumb {
        background: white;
      }

      &.fotorama__active {
        border: 1px solid #ff5501;
      }
    }
  }

  .fotorama__stage {
    border: 1px solid #E2E2E2;
    max-width: 650px;
    position: initial !important;
    margin-left: 100px;

    .fotorama__stage__shaft {
      max-width: 650px !important;
    }
  }
}

.fotorama__arr--next {
  display: block !important;
  right: 0 !important;
}

.fotorama__arr--prev {
  display: block !important;
}

.related {
  overflow: hidden;
  margin-top: 120px;

  .flickity-viewport {
    

    .product-item {
      width: 100% !important;
      padding-left: 0 !important;

      .product-item-info {
        width: 100%;
        height: 100%;
        margin: auto;
        text-align: center;
      }

    }
  }
}

.dot.is-selected {
  background: #FFC600 !important;
}

.product-options-wrapper {
    padding-top: 20px;
    .swatch-opt {
        outline: none !important;
    }
    .swatch-attribute {
        outline: none !important;
        .swatch-attribute-label {
            width: 100%;
            display: block;
        }
        .swatch-attribute-selected-option {
            width: 100%;
            display: block;
            padding-left: 0px;
        }
    }
}

.product-options-bottom {
    .field.qty {
        .label {
            padding-right: 20px !important;
        }
        .label, .control {
            display: table-cell !important;
        }
        .control {
            input[type="number"] {
                height: 65px;
            }
        }
    }
    .actions {
        vertical-align: top;
        padding-top: 0px !important;
    }
}

.price-tier_price {
    display: none;
}
