.section-item-title {
    display: none;
}

.main-menu-container {
    border-bottom: 1px solid #e2e2e2;
}

.menu {

    // Global Menu Settings
    &__list {
        margin: 0;
        padding: 0;
        li {
            &:first-child {
                a {
                    padding-left: 0px;
                }
            }
            &:last-child {
                a {
                    padding-right: 0px;
                }
            }
        }
    }
    &__item {
        display: inline-block;
        margin: 0;
        padding: 0;
    }
    &__link {
        display: block;
        padding: 0 6px;
    }

    &__mobile {
        @include media-breakpoint-up (sm) {
            display: none;
        }
    }

    // Main Menu Settings
    &--main {

        .menu {
            &__item {
                padding: 0 8px;
            }

            &__list {
                text-align: center;
            }
            &__link {
                line-height: 45px;
                color: $grey;
                position: relative;
                font-size: 14px;
                &:hover {
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                        width: 100%;
                        height: 4px;
                        background: $yellow;
                    }
                }
            }
        }

    }

    // Sub Menu Settings
    &--sub-menu {

        .menu {
            &__divider {
                border-right: 1px solid #FFF;
                padding-right: 8px;
            }
            &__item {
                color: #FFF;
                font-size: 12px;
                p {
                    margin-bottom: 0px;
                }
                i {
                    color: $yellow;
                    margin-right: 8px;
                    font-size: 18px;
                    vertical-align: middle;
                    margin-top: -3px;
                }
            }
            &__link {
                line-height: 70px;
                color: #FFF;
                font-size: 12px;
                &:hover {
                    color: $yellow;
                }
            }
        }

    }

}
