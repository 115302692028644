.checkout-cart-index {
  .page-title-wrapper {
    text-align: center;
    margin-top: 10px;

    .page-title {
      display: block !important;
      color: #FFC600;
    }
  }
}

.cart-container {

  #shopping-cart-table {

    thead {
      border-bottom: 1px solid #E2E2E2;
    }

    .cart.item {
      border: none;

      &:last-child {
        border-bottom: 1px solid #E2E2E2;
      }
    }

    .item-info {
      margin: 10px 0;
      .col {
        border-right: 1px solid #e2e2e2;

        .field.qty {
          .label {
            display: none;
          }

          .input-text.qty {
            border: none;
          }
        }

        &:last-child {
          border-right: none;
        }

        .actions-toolbar {
          a {
            color: #212529 !important;
            -webkit-appearance: initial;
          }
        }

      }
    }

    .col {
      font-size: 16px !important;
      text-align: left !important;
      padding: 10px 15px !important;


      .price {
        font-size: 15px !important;
      }
    }
  }

  .cart-summary {
    background: white;

    select, input {
      width: 100%;
    }

    .data.table.totals {
      width: 100%;
    }

    .title {
      margin-top: 0 !important;
      font-size: 32px !important;
      margin-bottom: 8px;
    }

    #block-shipping-heading, #block-discount-heading{
      font-size: 17px !important;
    }

    .action.primary.checkout {
      white-space: nowrap;
      font-size: 20px;
      z-index: 1;
      width: 150px;

      .zmdi {
        margin-left: 20px;
      }

        &:after {
        background: #93CE15 !important;
      }
    }

    .totals.sub {
      font-size: 14px;
    }

    .totals.shipping.excl {
      font-size: 14px;
    }

    .grand.totals {
      font-size: 20px;
    }

    .title {
      &:after{
        top: 12px !important;
      }

    }


  }

  .cart.main.actions {

    .action.continue {
      border: none;
      background: none;
      font-family: inherit;
      font-size: inherit;
      line-height: 46px;
      font-weight: inherit;
      color: #212529;

      &:before {
        line-height: inherit;
      }
    }

    #empty_cart_button {
      margin-right: 30px;
    }

    .action.update {
      &:before {
        line-height: 60px !important;
        float: right;
      }
    }

    :after {
      //background: #FFEAA1;
    }
  }
}

.coupon-block {
  display: inline-block;
  margin-top: 25px;

.fieldset.coupon {

  .field {
    background: #FFC600;
    color: white;
    display: inline-block;
    font-size: 14px;

    .label {
      padding: 0;
      text-align: left;
      width: auto;
      margin: 0 24px;
      display: inline-block;
      margin: 29px 24px 28px 24px;
      font-weight: initial;
    }

    .control {
      padding: 14px 0;
      display: inline-block;
      float: none;
      width: auto;
      margin-right: 14px;
    }

    input {
      background: #FFEAA1;
      border: none;
      width: 250px;
      height: 50px;
    }
  }

  .actions-toolbar {
    display: inline-block;
    margin-left: 10px !important;
    margin-bottom: -25px;

    .zmdi {
      padding-left: 22px;
    }
  }
}
}