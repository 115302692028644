.checkout-container {

  .payment-method._active {
    .checkout {
      font-size: 20px !important;
    }
  }

  .discount-code {
    display: none;
  }


  .opc-progress-bar-item {

    &._active {
      span {
        color: #FFC600;
        overflow: visible;

        &:before {
          background: #303030 !important;
        }
      }
    }

    &._complete {

      span {
        color: white;
        &:before {
          background: #93CE15;
        }
      }
    }

    span {
      color: white;
      font-family: 'futura-pt';
      font-weight: bold;
      &:before {
        background: #909090;
      }
      &:after {
        background: none;
        color: white;
        font-family: 'futura-pt' !important;
        top: 10px !important;
        font-size: 18px !important;
        line-height: 1 !important;
      }

    }

    &:first-child {
      span {
        &:after {
          content: '1' !important;
        }
      }
    }

    &:last-child {
      &:before {
        display: none;
      }
      span {
        &:after {
          content: '2' !important;
        }
      }
    }

    &:before {
      width: 85%;
      top: 19px;
      left: 107px;
      height: 1px;
      background: #e2e2e2 !important;
    }
  }

  .message {
    font-size: 16px !important;
  }

  .mage-error {
    font-size: 14px;
  }

  .checkout-shipping-method {
      .table-checkout-shipping-method {
          width: 100%;
      }

      #checkout-step-shipping_method {
          margin-bottom: 0px;
      }

     .actions-toolbar {
         width: 100%;
         margin-left: 0px !important;
     }

    .primary {

      &:after {
        background: #93CE15;
      }
    }

    .table-checkout-shipping-method {
      .price {
        font-weight: bold;
      }

      .row {
        margin: 0;
      }
    }

    input[type=radio] {
      margin-left: 15px;

    }
  }

  .opc-block-shipping-information {
      padding-right: 0px;

      .shipping-information-title {
          font-size: 24px !important;
          font-family: futura-pt;
          font-weight: bold;
      }
  }

  .opc-block-summary {
    margin-left: 150px;
    background: none;
    padding: 0;
    overflow: hidden;

    > .title {
      font-size: 24px !important;
      font-family: futura-pt;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .minicart-items-wrapper.overflowed {
      border-bottom: 1px solid #d1d1d1;
    }

    .minicart-items {
      padding: 20px;
    }

    .price {
      font-size: 16px !important;
    }

  }

  #checkout-step-shipping, .form-login, .payments {

    .field-tooltip {
      display: none;
    }

    .control {
      white-space: nowrap;

      &._with-tooltip {
      white-space: normal;
    }

      .label {
        display: none;
      }
    }

    input[type=text], input[type=number], input[type=email], input[type=password], select {
      width: 100%;
      border: 1px solid #E2E2E2;
      min-height: 50px;

      &#customer-email {
        width: 70%;
      }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      padding: 12px;

    }

    .label {
      padding-right: 35px !important;
      font-size: 1rem;
      direction: rtl;

      &:after {
        color: #FFC600;
        display: none;
      }
      &:before {
        color: #FFC600;
        content: '*';
      }
    }
    .field-error {
      font-size: 1rem;
    }

  }

  .opc-wrapper {
    padding: 30px;
    width: 70%;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);

    .step-title {
      font-size: 32px !important;
      font-family: futura-pt;
      font-weight: bold;
    }

    .form-shipping-address, .form-login {
      width: 100%;
      max-width: none;
      input[type="text"], input[type="email"] {
          padding: 12px 20px;
      }
    }

    .checkout-shipping-address {
      overflow: hidden;
    }

  }

  .opc-progress-bar {
    text-align: center;
    margin-top: 60px;

    ._active {

      &:before {
        background: #FFC600;
      }
      span {
        &:before {
          background: #FFC600;
        }
      }
    }
  }
  .authentication-wrapper {
    display: none;
  }
}

@media only screen and (max-width: 1400px) {
  #checkoutSteps {
    column-count: 1 !important;
  }
}

@media only screen and (max-width: 768px) {
  .checkout-shipping-method {
    margin-left: 0 !important;
    .primary {
      width: auto !important;
    }
  }

  .opc-block-summary {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  #checkout-step-shipping {
    input, select {
      width: 100% !important;
    }
  }
  aside.opc-sidebar {
    .modal-inner-wrap {
      position: fixed;
      right: 0;
      width: 80vw;
      height: 100vh;
      padding: 20px;
    }
  }
}

aside.opc-sidebar {
  width: 27%;
  .opc-block-summary {
    margin-left: 0;
  }
}

.opc-estimated-wrapper .counter.qty {
  background: #FFC600 !important;
}


.checkout-payment-method .field-select-billing, .checkout-payment-method .billing-address-form {
    max-width: none;
}
