.position-static {
    position: static;
}

.socialBlock {
    &__title {
        color: $yellow;
        font-size: 42px;
        margin-bottom: 30px;
    }
    &__linksTitle {
        margin-top: 700px;
        margin-bottom: 0px;
        color: $lightGrey;
    }
}

.instagrid {
    position: absolute;
    width: 100%;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
}

.instagram-image {
    display:inline-block;
    white-space: normal;
    margin-right: 30px;
    vertical-align: middle;
    img {
        width: 100%;
        height: auto;
    }
    &--full {
        width: 600px;
    }
    &--half {
        width: 285px;
        img {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.social-articles {
    background: #FFF;
    box-shadow: 0px 0px 20px 0 rgba(0,0,0,0.15);
    color: $lightGrey;
    min-height: 780px;
    padding-bottom: 25px;
    img {
        width: 100%;
    }
    &__title {
        .col-12.col-md-6 {
            &:last-child {
                p {
                    text-align: right;
                }
            }
        }
        p {
            padding: 25px;
            margin-bottom: 0px;
            a {
                color: $lightGrey;
                &:hover {
                    color: $yellow;
                }
            }
        }
        svg {
            margin-left: 25px;
            margin-bottom: 18px;
        }
    }
}

.article {
    padding: 15px 30px;

    &--large {
        padding-bottom: 5px;
    }

    &--small {
        padding: 0 30px;
        i {
            float: right;
            margin-top: 5px;
        }
    }

    &__title {
        &--large {
            color: $yellow;
            font-size: 36px;
        }
        &--small {
            padding-bottom: 5px;
            a {
                color: $lightGrey;
            }
        }
    }
    p {
        color: $grey;
        font-size: 14px;
        line-height: 1.75;
    }
    hr {
        border: 0px;
        border-bottom: 1px solid #e2e2e2;
    }
    .action {
        z-index: 1;
        display: inline-block;
        font-size: 16px;
    }
}

.social-icons {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-bottom: 60px;
    margin-top: 10px;
    li {
        display: inline-block;
        margin-right: 8px;
    }
}
