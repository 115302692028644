.generic-page {
  font-size: 14px;
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: auto;

  .action {
    display: inline-block;

    a {
      text-decoration: none;
      color: #212529;
    }
    &:after {
      background: #FFEAA1;
    }
  }
}

#contact-form {
  padding-top: 30px;
  padding-bottom: 30px;

  input, select, textarea {
    max-width: 460px;
    width: 100%;
    border: 1px solid #E2E2E2;
    min-height: 50px;
  }


  .required {
    .label {
      &:after {
        color: #FFC600 !important;
      }
    }
  }
}

.cms-no-route {
  .column.main {
    width: 80%;
    padding: 40px 0;
    margin: auto;
  }
}