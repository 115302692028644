.footer {
  &.content {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
}

.newsletter {
  &--footer {
    background: $yellow;
    color: #FFF;
    padding-top: 40px;
    padding-bottom: 40px;

    form {
      display: flex;
    }

    .title {
      font-size: 36px;
      font-family: "futura-pt";
      margin-bottom: 15px;
      line-height: 1;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
    }

    svg {
      margin-bottom: 15px;
    }

    input[type="text"], input[type="email"] {
      width: 100%;
      background: $lightYellow;
      padding: 12px 20px;
      margin-bottom: 20px;
      border: 0;
      outline: none;
    }

    input[type="email"] {
      margin-bottom: 0;
    }

    input[type="checkbox"] {
      margin-right: 4px;
    }

    button {
      background: $grey !important;
      color: #FFF !important;
      width: 70%;
      margin-bottom: 20px;
    }

    label {
      font-size: 14px;
    }

  }
}

.footer.content {
  .footer-menu {
    padding-top: 60px;

    li {
      margin-bottom: 5px;
    }

    ul {
      margin-bottom: 20px;
      > li {
        font-size: 18px;
      }
      ul {
        > li {
          font-family: "poppins";
          font-size: 14px;
          a {
            color: #212529;
          }
        }
      }
    }



    .directions {
      z-index: 1;
      display: inline-block;
      background: #FFEAA1;

      a {
        color: #212529;
        text-decoration: none !important;
        font-weight: normal;

        &:after {
          font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
          content: '\f2fb';
          position: relative;
          top: 7px;
          font-size: 31px;
          margin-left: 10px;
        }
      }
    }

  }
}
.electric {
  font-size: 13px;
  text-align: right;

  img {
    margin: 0 0 0 auto;
    display: block;
  }

  a {
    color: #212529;
  }
}
