.messages {
    .message {
        margin: 0px 0 0px 0;
        padding: 0px !important;
        box-shadow: inset 0px 4px 4px 0px rgba(0,0,0,0.15);
        .message-icon {
            padding: 20px 0px;
            font-size: 16px;
        }
    }
}

.message.info {
    @include lib-message-icon-inner(info);
}

.message.error {
    @include lib-message-icon-inner(error);
}

.message.warning {
    @include lib-message-icon-inner(warning);
}

.message.notice {
    @include lib-message-icon-inner(notice);
}

.message.success {
    @include lib-message-icon-inner(success);
}
