.login-container, .form-create-account, .form-address-edit, .form-edit-account, .forget {
  margin-top: 30px;
  margin-bottom: 30px;

  input[type=text], input[type=number], input[type=email], input[type=password], select {
    width: 100%;
    border: 1px solid #E2E2E2;
    max-width: 400px;
    min-height: 50px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 12px;

  }

  .required {
    .label {
      &:after {
        color: #FFC600 !important;
      }
    }
  }

  .fieldset {
    &:after {
      color: #FFC600 !important;
    }
  }

  .secondary {
    margin-left: 25%;

    .remind, .back {
      display: inline-block;
      margin-top: 0 !important;
      color: #303030;
      float: right;
      &:after {
        background: #FFEAA1;
      }
    }
  }

  .action.create.primary {
    display: inline-block;
  }
  .legend {
    font-size: 24px;
  }
  #password-strength-meter-container {
    max-width: 400px;
  }
}

.account .columns {
  margin-top: 20px;
}

.account {
  .account-columns {
    margin-bottom: 20px;
    .block-dashboard-addresses {
      .action.edit {
        color: #007bff;
      }
    }
    .box {
      .change-password {
        margin-left: 40px;
      }
      width: 42%;
      display: inline-block;
      margin-right: 30px;
      .action {
        color: #007bff;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    .nav.item {
      a {
        color: #212529;
      }
    }

    .action {
      display: inline-block;
      color: #212529;

      &.save {
        font-size: 1.5rem;
      }
    }
  }
}

.actions-toolbar {
  display: inline-block;
  .secondary {
    float: left;
    a {
      display: inline !important;
    }
  }
}